import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
	Container,
  FormGroup,
  Label,
  Button,
  Form,
  Input,
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import * as url from "../../../helpers/url_helper"
import {SOLO_API_URL} from "../../../helpers/api_helper";

class SoloPolicyEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
		type: '',
		daily_task: 1, 
		monthly_task: 1,
        _id: ""
    }
  }
//   updateItem = (e) => {
//     // e.preventDefault();
//     console.log(e);
//     this.setState({
// 		daily_task:  e.daily_task, 
// 		monthly_task:  e.monthly_task,
//         _id: e._id
//     })
//   }
  	componentDidMount(){
		const { data } = this.props.location
		console.log(this.props.location)
		const { type, daily_task, monthly_task, _id } = data;
		this.setState({
			type,
			daily_task, 
			monthly_task,
			_id
		})
	}
	handleValidSubmit = (e) => {
		e.preventDefault();
		const data = this.state;
		let body;
		const { daily_task, monthly_task, _id } = data;

		const BToken = localStorage.getItem("gameAuth");
		const BearerToken = JSON.parse(BToken);

		fetch(`${SOLO_API_URL}${url.PUT_DASHBOARD_LOOKSEE_POLICY_UPDATE}/${_id}`, {
			method: 'put',
			headers: {
				'Authorization': `${BearerToken}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				_id,
				daily_task,
				monthly_task
			})
		})
		.then(res => res.json())
		.then(data => {
			const {
				status,
				entity
			} = data ;
			if(status) {
				this.props.history.push("/solo-config-list")
			}
		})
	}

	handleInputChange = (e) => {
		this.setState({
			[e.target.name] : e.target.value
		})
	}


  render() {
	// console.log(this.state);
	const {type, daily_task, monthly_task, _id } = this.state;
	console.log(type);

    return (
      <React.Fragment>
		  <div className="page-content">
          <MetaTags>
            <title>Game | AP - Looksee Solo</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={("Looksee Solo Policy")}
              breadcrumbItem={("Edit Free Usage limits")}
            />
        <Col md={12}>
          <Card>
            <CardBody>
              	<CardTitle className="h4"></CardTitle>				  

				  <Form onSubmit={this.handleValidSubmit}>
						<FormGroup className="row mb-4">
						<Label
							htmlFor="horizontal-daily_task-Input"
							className="col-sm-1 col-form-label text-md-right"
							style={{textAlign: "center"}}
						>
							{ type }
						</Label>
						<Col sm={6}>
							<Input
							value={(type === "Daily")?  daily_task: monthly_task}
							type="number"
							name={( type === "Daily")?  "daily_task": "monthly_task"}
							className="form-control"
							id="horizontal-daily_task-Input"
							onChange={this.handleInputChange}
							
							/>
						</Col>
						</FormGroup>
						
						<FormGroup className="row justify-content-end">
						<Col sm={11}>
							<div>
							<Button
								type="submit"
								color="primary"
								className="w-md"
							>
								Update
							</Button> &nbsp; &nbsp; &nbsp; <span className="loading"></span>
							</div>
						</Col>
						</FormGroup>
					</Form>
            </CardBody>
          </Card>
        </Col>

		</Container>
        </div>
	  </React.Fragment>
    )
  }
}

SoloPolicyEdit.propTypes = {
	t: PropTypes.any
}
export default SoloPolicyEdit
