import React, { Component,  useState, useEffect } from "react"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Pagination
} from "reactstrap"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import "./style.css";
import * as url from "../../helpers/url_helper"
import { API_URL} from "../../helpers/api_helper";
import _ from "lodash";

const GameList = ({game_list, type}) =>  {

  const downloadFile = ({ game_name, game_code, players}) =>  {
    // console.log(players);
    if(type === 'ios') {
      players.map(ele => {
        window.open(`https://${game_name}-success.s3.amazonaws.com/${game_code}-${ele}.txt`, "_blank")
      });
    }

    if(type === 'android') {      
      console.log(players);
      players.map(ele => {
        window.open(`https://android-${game_name}-complete.s3.amazonaws.com/${game_code}-${ele}.txt`, "_blank")
      });
    }    

    const BToken = localStorage.getItem("gameAuth");
    const BearerToken = JSON.parse(BToken);
    let BASE_API_URL = ""
    if(game_name === "avalon") {
      BASE_API_URL = `${API_URL}${url.GET_DASHBOARD_GAME_LOGS_AVALON}`
    } else if(game_name === "mafia") {
      BASE_API_URL = `${API_URL}${url.GET_DASHBOARD_GAME_LOGS_MAFIA}`
    } else if(game_name === "resistance") {
      BASE_API_URL = `${API_URL}${url.GET_DASHBOARD_GAME_LOGS_RESISTANCE}`
    } else if(game_name === "werewolf") {
      BASE_API_URL = `${API_URL}${url.GET_DASHBOARD_GAME_LOGS_WEREWOLF}`
    }       
    // console.log(JSON.stringify(players));
    fetch(`${BASE_API_URL}?game_code=${game_code}`, {
      method: 'get',
      headers: {
      'Authorization': `${BearerToken}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(async data => {
      const {
      entiy,
      message,
      code
      } = data;
      // await fileDownload(`${entiy.join("<br><br>")}`, `${game_code}_backend_logs.html`);
      //Open window
      let myWindow =  window.open(`${game_code}.html`, `_blank`);
      myWindow.document.write(`${entiy.join("<br><br>")}`);
  
    }).catch(function (error) {
      console.log(error);
    });    
  }  
    
    const [currentPage, setCurrentPage] = useState(1);
    
    const pageSize = 10;
    const pageCount = (game_list)? Math.ceil(game_list.length/pageSize): 0;
    const [paginatedList, setPaginatedList] = useState(_(game_list).slice(0).take(pageSize).value());
    const pagination = (pageNo) => {
      setCurrentPage(pageNo);
      const startIndex = (pageNo -1) * pageSize;
      const paginaatedList = _(game_list).slice(startIndex).take(pageSize).value();
      setPaginatedList(paginaatedList);
      // console.log(paginaatedList);
    }
  
    // if(pageCount === 1) return null;
    let  pages = [];
    if(game_list.length > pageSize ) {
      pages = _.range(1, pageCount +1);
      
    } 
    console.log(pages);
    
    useEffect(() => {
      setPaginatedList([... _(game_list).slice(0).take(pageSize).value() ]);
      setCurrentPage(1);
    
    }, [game_list]);

    return (
      <React.Fragment> 
        <Col md={12}>
            <Card>
              <CardBody>
                <div className="table-responsive">

                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Game Code</th>
                      <th>Players</th>
                      <th>Logs</th>
                    </tr>
                  </thead>
                  <tbody>
                  {/* <tr key="3">
                        <td>3</td>
                        <td>3</td>
                        <td>3</td>
                        <td> <Button 
                          variant="secondary" 
                          size="sm" 
                          onClick={ () => this.downloadFile({game_name:`werewolf`, game_code: 'VJXKPN', players: 'VJXKPN'})}> Open </Button>
                        </td>
                      </tr> */}
                    {/* {game_list.map(ele => (
                      <tr key={ele.SERIAL_NO}>
                        <td>{ele.SERIAL_NO}</td>
                        <td>{ele.game_code}</td>
                        <td>{ele.number_of_player}</td>
                        <td> <Button 
                          variant="secondary" 
                          size="sm" 
                          onClick={ () => this.downloadFile({game_name: ele.game_name, game_code: ele.game_code, players: ele.players })}> Open </Button>
                        </td>
                      </tr>
                    ))} */}

                    {paginatedList.map(ele => (
                      <tr key={ele.SERIAL_NO}>
                        <td>{ele.SERIAL_NO}</td>
                        <td>{ele.game_code}</td>
                        <td>{ele.number_of_player}</td>
                        <td> <Button 
                          variant="secondary" 
                          size="sm" 
                          onClick={ () => downloadFile({game_name: ele.game_name, game_code: ele.game_code, players: ele.players })}> Open </Button>
                        </td>
                      </tr>
                    ))}                  
                  </tbody>
                </Table>
                <nav className="d-flex justify-content-center"> 
                  <ul className="pagination">
                    {
                      pages.map(page => (
                        <li className={
                          (page === currentPage)? "page-item active": "page-item"
                        }
                        > 
                        <p className="page-link" onClick= {() => pagination(page)}>{page}</p>
                        </li>
                      ))
                    }             
                  </ul>
                </nav>
                </div>
              </CardBody>
            </Card>
          </Col>

      </React.Fragment>
    )
}

export default GameList
