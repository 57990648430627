import React, { Component } from "react"
import {
  Card,
  CardBody,
  Col,
} from "reactstrap"
export default class Redeemed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
    this.toggledropdown = this.toggledropdown.bind(this)
  }

  toggledropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }
  render() {
    const {redeemed_iphone, redeemed_ipad, redeemed_mac, redeemed_pc} = this.props;
    return (
      <React.Fragment>  
		  <h5>Redeemed</h5>      
        <Col xl={3}>          
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <h5 className="card-title mb-3 me-2">iPhone</h5>                
              </div>

              <div className="d-flex flex-wrap">
                <div>
                    <h4 className="mb-3">{redeemed_iphone}</h4>
                </div>
                <div className="ms-auto align-self-end">
                  <i className="bx bx-group display-4 text-light"></i>
                </div>
              </div>
            </CardBody>
          </Card>          
        </Col>
		    <Col xl={3}>          
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <h5 className="card-title mb-3 me-2">iPad</h5>                
              </div>

              <div className="d-flex flex-wrap">
                <div>
                    <h4 className="mb-3">{redeemed_ipad}</h4>
                </div>
                <div className="ms-auto align-self-end">
                  <i className="bx bx-group display-4 text-light"></i>
                </div>
              </div>
            </CardBody>
          </Card>          
        </Col>
		    <Col xl={3}>          
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <h5 className="card-title mb-3 me-2">Mac</h5>                
              </div>

              <div className="d-flex flex-wrap">
                <div>
                    <h4 className="mb-3">{redeemed_mac}</h4>
                </div>
                <div className="ms-auto align-self-end">
                  <i className="bx bx-group display-4 text-light"></i>
                </div>
              </div>
            </CardBody>
          </Card>          
        </Col>
		    <Col xl={3}>          
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <h5 className="card-title mb-3 me-2">PC</h5>                
              </div>

              <div className="d-flex flex-wrap">
                <div>
                    <h4 className="mb-3">{redeemed_pc}</h4>
                </div>
                <div className="ms-auto align-self-end">
                  <i className="bx bx-group display-4 text-light"></i>
                </div>
              </div>
            </CardBody>
          </Card>          
        </Col>
      </React.Fragment>
    )
  }
}
