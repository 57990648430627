import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Table,
  CardTitle,
} from "reactstrap"


export default class Subscribers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
  }

  render() {
    const {subscribers} = this.props;
    return (
      <React.Fragment>        
        <Col md={12}>
            <CardTitle className="h4">Subscribers</CardTitle>
            <Card>
              <CardBody>
                <div className="table-responsive">
                <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th style={{width: "10%"}}>#</th>
                          <th style={{width: "50%"}}>Country Name</th>
                          <th style={{width: "40%"}}>Subcribers</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          (subscribers.lenth)?
                          subscribers.map((type_data, index) => (
                            <tr key={Math.random()}>
                              <th scope="row" style= {{width: "10%"}}>{index + 1}</th>
                              <td style={{width: "50%"}}>{type_data._id}</td>
                              <td style={{width: "40%"}}>{type_data.total}</td>
                            </tr>
                          )) : `No Data`
                        }
                      </tbody>
                    </Table>
                  </div>

              </CardBody>
            </Card>
          </Col>
      </React.Fragment>
    )
  }
}
