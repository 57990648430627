import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
} from "reactstrap"


export default class InAppPurchase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
    this.toggledropdown = this.toggledropdown.bind(this)
  }

  toggledropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }
  render() {
    const {dataCount, title} = this.props;
    return (
      <React.Fragment>        
        <Col xl={12}>          
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <h5 className="card-title mb-3 me-2">{title}</h5>
              </div>

              <div className="d-flex flex-wrap">
                <div>
                  <h4 className="mb-3">{dataCount}</h4>
                </div>
                <div className="ms-auto align-self-end">
                  <i className="bx bx-dollar display-4 text-light"></i>
                </div>
              </div>
            </CardBody>
          </Card>          
        </Col>
      </React.Fragment>
    )
  }
}
