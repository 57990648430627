//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/login"

export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"


//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
// ============================================== New ==================

//REGISTER
// export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const FACEBOOK_LOGIN = "/login_with_facebook"
export const GOOGLE_LOGIN = "/login_with_google"
export const APPLE_LOGIN = "/login_with_apple"

// Dashboard
export const GET_DASHBOARD_COUNTER_INFO = "/universal/counter_info"
export const GET_DASHBOARD_GAME_WISH_INFO = "/universal/game_wish_info"
export const GET_DASHBOARD_GAME_PLAYER_MAFIA = "/mafia/game-player"
export const GET_DASHBOARD_GAME_PLAYER_AVALON = "/avalon/game-player"
export const GET_DASHBOARD_GAME_PLAYER_RESISTANCE = "/resistance/game-player"
export const GET_DASHBOARD_GAME_PLAYER_WEREWOLF = "/werewolf/game-player"

// Game Logs
export const GET_DASHBOARD_GAME_LOGS_MAFIA = '/mafia/game_logs'
export const GET_DASHBOARD_GAME_LOGS_AVALON = '/avalon/game_logs'
export const GET_DASHBOARD_GAME_LOGS_RESISTANCE = '/resistance/game_logs'
export const GET_DASHBOARD_GAME_LOGS_WEREWOLF = '/werewolf/game_logs'

// Date Range
export const GET_WEB_GAME_DATE_RANGE_INFO = '/date_range_info' 
export const GET_DASHBOARD_DATE_RANGE_INFO = '/universal/date_range_info'
export const MAFIA_DASHBOARD_DATE_RANGE_INFO = '/mafia/date_range_info'
export const WEREWOLF_DASHBOARD_DATE_RANGE_INFO = '/werewolf/date_range_info'
export const RESISTANCE_DASHBOARD_DATE_RANGE_INFO = '/resistance/date_range_info'
export const AVALON_DASHBOARD_DATE_RANGE_INFO = '/avalon/date_range_info'

// FOR FCM Notification
export const POST_DASHBOARD_FCM_SEND_NOTIFICATION = '/universal/fcm_send_notification'
export const POST_DASHBOARD_COIN_PROMOTION_NOTIFICATION = '/universal/promo_notification'
export const POST_FCM_SEND_NOTIFICATION = '/fcm_send_notification'
//  Game Config
export const GET_DASHBOARD_GAME_CONFIG = '/universal/config-group'
export const PUT_DASHBOARD_GAME_CONFIG = '/universal/config'
export const GET_DASHBOARD_GAME_APP_VERSION = '/universal/app_version'
export const PUT_DASHBOARD_UPDATE_APP_VERSION = "/universal/app_version"

// Looksee config
export const GET_DASHBOARD_LOOKSEE_CONFIG = '/all_config'
export const PUT_DASHBOARD_LOOKSEE_CONFIG = '/config'

export const GET_DASHBOARD_USERS_INFO = '/users_info'
export const POST_DASHBOARD_LOOKSEE_FCM_SEND_NOTIFICATION = '/fcm_send_notification'
export const GET_DASHBOARD_LOOKSEE_DATE_RANGE_INFO = '/date_range_report'
export const GET_DASHBOARD_LOOKSEE_POLICY = "/looksee_policy"
export const PUT_DASHBOARD_LOOKSEE_POLICY_UPDATE = "/looksee_policy"
export const POST_LOOKSEE_PROMO = '/promo'
export const GET_LOOKSEE_PROMO_COUNT = '/count-promo'
export const GET_DASHBOARD_LOOKSEE_PROMO = '/promo-count'
export const GET_DASHBOARD_DATE_RANGE = '/date_range_info'

//  Enterprise 
export const GET_DASHBOARD_INFO = '/dashboard'
export const GET_DASHBOARD_WEB_INFO = '/web-dashboard'


//USER
export const PUT_SETUP_PROFILE = "/setup_profile"
export const PUT_UPDATE_PROFILE = "/update_profile"
export const GET_PREMIUM_INFO = "/premium_info"
export const GET_CLOSE_ACCOUNT = "/close_account"

//TASK
export const POST_CREATE_TASK = "/create_task"
export const GET_SYNC_TASK = "/sync_task"
export const PUT_COMPLETE_TASK = "/complete_task"
export const PUT_DELETE_TASK = "/delete_task"
export const PUT_ABANDON_TASK = "/abandon_task"
export const POST_RECURRING_TASK = "/recurring_task"
export const GET_RECURRING_TASK_HISTORY = "/recurring_task_history"
export const PUT_END_RECURRENCE = "/end_recurrence"
export const GET_SKIP_ONCE = "/skip_once"

//PROJECTS
export const GET_PROJECTS = "/tasks"
export const GET_PROJECT_DETAIL = "/task"

//CUSTOM OFFER
export const POST_ADD_CUSTOM_OFFER = "/add_custom_offer"
export const GET_CUSTOM_OFFER = "/get_custom_offer"
export const DELETE_CUSTOM_OFFER = "/delete_custom_offer"

//SUBSCRIPTION
export const POST_RECEIPT_VALIDATION = "/receipt-validation"
export const POST_SUBSCRIPTION_NOTIFICATION = "/subscription_notification"
export const GET_UPDATE_SUBSCRIPTION = "/update_subscription"

//VERSION
export const GET_CHECK_VERSION = "/check_version"

// Game Promo
export const POST_GAME_PROMO = '/universal/game-promo'
export const GET_GAME_PROMO_COUNT = '/universal/count-game-promo'
export const GET_DASHBOARD_GAME_PROMO = '/universal/promo-count'
