import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"


export default class Tasks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
  }

  render() {
    const {total_task} = this.props;
    return (
      <React.Fragment>                
        <Col xl={12}>          
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <h5 className="card-title mb-3 me-2">Tasks</h5>
              </div>

              <div className="d-flex flex-wrap">
                <div>
                    <h4 className="mb-3">{total_task}</h4>
                </div>
                <div className="ms-auto align-self-end">
                  <i className="bx bx-grid display-6 text-light"></i>
                </div>
              </div>
            </CardBody>
          </Card>          
        </Col>
      </React.Fragment>
    )
  }
}
