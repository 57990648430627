import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import * as url from "../../../helpers/url_helper"
import {SOLO_API_URL} from "../../../helpers/api_helper";

// Pages Components
import Users from "./Users"
import Subscribers from "./Subscribers"
import FcmNotifiForm from "./FcmNotifiForm"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

class SoloUserInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      totalUser: 0,
      mobileAppUser: 0,
      webAppUser: 0,
      mobileAppSubscription: 0,
      webAppSubscription: 0
    }
  }
  
  componentDidMount() {
    const BToken = localStorage.getItem("gameAuth");
    const BearerToken = JSON.parse(BToken);
    // console.log(BearerToken)
    fetch(`${SOLO_API_URL}${url.GET_DASHBOARD_USERS_INFO}`, {
      method: 'get',
      headers: {
        'Authorization': `${BearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(data => {
      const {
        status,
        totalUser,
        mobileAppUser,
        webAppUser,
        mobileAppSubscription,
        webAppSubscription
      } = data ; 
      console.log({totalUser, mobileAppUser, webAppUser, mobileAppSubscription, webAppSubscription})
      this.setState({ 
        totalUser,
        mobileAppUser,
        webAppUser,
        mobileAppSubscription,
        webAppSubscription
      })
  
    }).catch(function (error) {
      console.log(error);
    });    

  }


  render() {
    const {totalUser, mobileAppUser, webAppUser, mobileAppSubscription, webAppSubscription} = this.state;
    
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Game | AP - User List</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Admin Panel")}
              breadcrumbItem={this.props.t("Looksee Solo")}
            />

            <Row>
              <Col xl={12}> 
                <Users total_user={totalUser} mobile_user={mobileAppUser} web_user={webAppUser} />
              </Col>
              <Col xl={12}> 
                <Subscribers total_subscribers={mobileAppSubscription + webAppSubscription} mobile_subscribers={mobileAppSubscription} web_subscribers={webAppSubscription} />
              </Col>
            </Row>
            <Row>
              <FcmNotifiForm />
            </Row>
          </Container>
        </div>
        
      </React.Fragment>
    )
  }
}

SoloUserInfo.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(SoloUserInfo)
