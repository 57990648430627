import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"

import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment'
import $ from 'jquery'
import "flatpickr/dist/themes/material_blue.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import { Calendar, DateRangePicker } from 'react-date-range';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import TotalNewUsers from "./TotalNewUsers"
import TotalGamePlayed from "./TotalGamePlayed"
import InAppPurchase from "./InAppPurchase"
import GameHost from "./GameHost"
import TotalGames from "./TotalGames"
import IncompleteGames from "./IncompleteGames"
import GameList from "./GameList"

import * as url from "../../helpers/url_helper"
import {
  ENTERPRISE_API_URL
} from "../../helpers/api_helper";


class EnterpriseAndroidReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      start: moment().subtract(-1, 'days'),
      end: moment(),
      totalUser: 0,
      inAppPurchaseCounter: 0,
      totalGamePlayer: 0,
      gamePlayersCount: 0,
      successGameCount: 0,
      unsuccessGameCount: 0,
      hostGameCount: 0,
      game_list:[],
      basic: true,
    }       
  }  

  render() {
    const { start, end } = this.state;
    const { 
      totalUser,
      inAppPurchaseCounter,
      totalGamePlayer,
      gamePlayersCount,
      successGameCount,
      unsuccessGameCount,
      hostGameCount,
      game_list,
      loading 
    } = this.state;

    const handleCallback =(start, end) => {
      
      this.setState({ start, end });
      let start_date = start.format('MM/DD/YYYY');
      let end_date = end.format('MM/DD/YYYY');
      $(".loadingIcon").text("Loading ...").css('color', 'red');

      const BToken = localStorage.getItem("gameAuth");
      const BearerToken = JSON.parse(BToken);
      Promise.all([
        fetch(`${ENTERPRISE_API_URL}${url.GET_DASHBOARD_DATE_RANGE}?os_name=Android&start=${start_date}&end=${end_date}`, {
          method: 'get',
          headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(value => value.json()),
      ]).then(([ DashboardInfo]) => {
        const {
          totalUsers,
          totalGamePlayers,
          successGamesCount, 
          unseccessGamesCount,
          hostGameCount,
          inAppPurchases,
          game_list,
        } = DashboardInfo ; 
    
        $(".loadingIcon").text("");
        this.setState({
          totalGamePlayer: totalGamePlayers,
          totalUser: totalUsers,
          inAppPurchaseCounter: inAppPurchases,
    
          successGameCount: successGamesCount,
          unsuccessGameCount: unseccessGamesCount,
          hostGameCount: hostGameCount,
          game_list: game_list,
          loading: true
        })
      }).catch((err) => {
        console.log(err);
      });
    };

    const handleApply = (event, picker) => {
      picker.element.val(
        picker.startDate.format('MM/DD/YYYY') +
          ' - ' +
          picker.endDate.format('MM/DD/YYYY')
      );
    };
    const handleCancel = (event, picker) => {
      picker.element.val('');
    };
     
    const label = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');
    

    return (
      <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Admin Panel | Android Date Range</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Admin Panel" breadcrumbItem="Enterprise Android Report" />
            
            <Row>
              <Card>
                <CardBody>
                  <Form>
                      <Col lg="6">
                        <FormGroup className="mb-8">
                          <Label>Select Date Range <span className="loadingIcon"></span></Label>
                          <InputGroup>
                          <DateRangePicker
                            initialSettings={{
                              autoUpdateInput: false,
                              startDate: start.toDate(),
                              endDate: end.toDate(),
                              ranges: {
                                // "No Date": "",
                                Today: [moment().toDate(), moment().toDate()],
                                Yesterday: [
                                  moment().subtract(1, 'days').toDate(),
                                  moment().subtract(1, 'days').toDate(),
                                ],
                                'Last 7 Days': [
                                  moment().subtract(6, 'days').toDate(),
                                  moment().toDate(),
                                ],
                                'Last 30 Days': [
                                  moment().subtract(29, 'days').toDate(),
                                  moment().toDate(),
                                ],
                                'This Month': [
                                  moment().startOf('month').toDate(),
                                  moment().endOf('month').toDate(),
                                ],
                                'Last Month': [
                                  moment().subtract(1, 'month').startOf('month').toDate(),
                                  moment().subtract(1, 'month').endOf('month').toDate(),
                                ],
                              },
                            }}
                            
                            onApply={handleApply}
                            onCancel={handleCancel}
                            onCallback={handleCallback}
                            > 
                            <div
                              id="reportrange"
                              className="col-4"
                              style={{
                                background: '#fff',
                                cursor: 'pointer',
                                padding: '5px 10px',
                                border: '1px solid #ccc',
                                width: '100%',
                              }}
                            >
                              <i className="fa fa-calendar"></i>&nbsp;
                              <span>{(loading)? label: ""}</span> <i className="fa fa-caret-down"></i>
                            </div>
                            </DateRangePicker>            
                          </InputGroup>
                        </FormGroup>
                      </Col>
                  </Form>
                </CardBody>
              </Card>
            </Row>
            
            { (loading) && <Row>
                <Col xl={4}> 
                  <TotalNewUsers user={totalUser} />
                  <GameList game_list={game_list} type="Android"/>
                </Col>
                <Col xl={4}> 
                  <TotalGames user={successGameCount} />
                  <TotalGamePlayed totalPlayers = {totalGamePlayer}/>
                  <GameHost user={hostGameCount}/>
                  <IncompleteGames user={unsuccessGameCount} />
                  <InAppPurchase inAppCounter={inAppPurchaseCounter}/>
                </Col>
              </Row>
            }
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default EnterpriseAndroidReport
