import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import * as url from "../../helpers/url_helper"
import {API_URL} from "../../helpers/api_helper";
import { Link } from "react-router-dom"


// Pages Components
import TotalUsers from "./TotalUsers"
import TotalGamePlayers from "./TotalGamePlayers"
import TotalSubscribes from "./TotalSubscribes"
import InAppPurchase from "./InAppPurchase"
import VideoAds from "./VideoAds"
import FcmNotifiForm from "./FcmNotifiForm"
import PromoCoinsNotify from "./PromoCoinsNotify"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

class Avalon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      totalUser: 0,
      totalSubscribers: 0,
      TotalAds: 0, 
      RewardedAds: 0, 
      PostGameAds: 0,
      inAppPurchaseCounter: 0,
      totalGamePlayer: 0,
      totalGames: 0
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }
  componentWillMount() {
    
  }
  
  componentDidMount() {
    setTimeout(() => this.setState({ subscribemodal: false }), 2000);
    const BToken = localStorage.getItem("gameAuth");
    const BearerToken = JSON.parse(BToken);
    // console.log(BearerToken)
    fetch(`${API_URL}${url.GET_DASHBOARD_GAME_WISH_INFO}?game_name=Avalon&os_name=IOS`, {
      method: 'get',
      headers: {
        'Authorization': `${BearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(data => {
      const {
        status,
        TotalAds,  
        RewardedAds,  
        PostGameAds,
        // totalSubscribers,
        totalUser,
        inAppPurchaseCounter
      } = data ; 
  
      this.setState({ 
        TotalAds,  
        RewardedAds,  
        PostGameAds,
        // totalSubscribers,
        totalUser,
        inAppPurchaseCounter
      })
  
    }).catch(function (error) {
      console.log(error);
    });
    Promise.all([
      fetch(`${API_URL}${url.GET_DASHBOARD_GAME_PLAYER_AVALON}?os_name=IOS`, {
          method: 'get',
          headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(value => value.json()),      
    ]).then(([registanceInfo]) => {
        const {
          totalGames,
          gamePlayersCount
        } = registanceInfo;
        
        this.setState({
          totalGames, 
          totalGamePlayer: gamePlayersCount
        })
    }).catch((err) => {
        console.log(err);
    });

  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  render() {
    const {
      TotalAds,
      RewardedAds,
      PostGameAds, 
      totalSubscribers, 
      totalUser, 
      inAppPurchaseCounter,
      totalGames, 
      totalGamePlayer 
    } = this.state;
    
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Game | AP - Avalon-iOS</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Admin Panel")}
              breadcrumbItem={this.props.t("Avalon")}
            />

            <Row>
              <Col xl={4}> 
                <TotalUsers user={totalUser} />
                {/* <TotalSubscribes subscribes={totalSubscribers}/> */}
              </Col>
              <Col xl={4}> 
                <TotalGamePlayers totalGames={totalGames} totalPlayers = {totalGamePlayer}/>
                <InAppPurchase inAppCounter={inAppPurchaseCounter}/>
              </Col>
              <Col xl={4}> 
                <VideoAds total={TotalAds} rewarded={RewardedAds} postgame={PostGameAds} />
              </Col>
            </Row>
            <Row>
              <FcmNotifiForm />
            </Row>
            <Row>
              <PromoCoinsNotify />
            </Row>
          </Container>
        </div>
        
      </React.Fragment>
    )
  }
}

Avalon.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Avalon)
