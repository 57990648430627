module.exports = {
  google: {
    API_KEY: "AIzaSyAPS2hGvXQPUwi3eFSEScunHwpK2bk9E_Q",
    CLIENT_ID:"532598680232-3tiuomcpu54ct6bg8nj40nn3hqal2cna.apps.googleusercontent.com",
    SECRET: "avM0_EXxKzHidhNLzmdHXh70",
  },
  facebook: {
    APP_ID: "736214810111573",    // My account app id need to change before live
  },
  apple: {
    CLIENT_ID: "com.looksee.solo.web",    // My account app id need to change before live
  },
}
