import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  Table,
  CardTitle,
} from "reactstrap"

export default class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
    this.toggledropdown = this.toggledropdown.bind(this)
  }

  toggledropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }
  render() {
    const {total_user, mobile_user, web_user} = this.props;
    return (
      <React.Fragment>
        <Row>
          <Col xl={4}>          
            <Card>
              <CardBody>
                <div className="d-flex flex-wrap">
                  <h5 className="card-title mb-3 me-2">Users</h5>
                </div>

                <div className="d-flex flex-wrap">
                  <div>
                    <h4 className="mb-3">{total_user}</h4>                  
                  </div>
                  <div className="ms-auto align-self-end">
                    <i className="bx bx-user-plus display-4 text-light"></i>
                  </div>
                </div>
              </CardBody>
            </Card>          
          </Col>
          <Col xl={4}>          
            <Card>
              <CardBody>
                <div className="d-flex flex-wrap">
                  <h5 className="card-title mb-3 me-2">Mobile App Users</h5>
                </div>

                <div className="d-flex flex-wrap">
                  <div>
                    <h4 className="mb-3">{mobile_user}</h4>                  
                  </div>
                  <div className="ms-auto align-self-end">
                    <i className="bx bx-user-plus display-4 text-light"></i>
                  </div>
                </div>
              </CardBody>
            </Card>          
          </Col>
          <Col xl={4}>          
            <Card>
              <CardBody>
                <div className="d-flex flex-wrap">
                  <h5 className="card-title mb-3 me-2">Web App Users</h5>
                </div>

                <div className="d-flex flex-wrap">
                  <div>
                    <h4 className="mb-3">{web_user}</h4>                  
                  </div>
                  <div className="ms-auto align-self-end">
                    <i className="bx bx-user-plus display-4 text-light"></i>
                  </div>
                </div>
              </CardBody>
            </Card>          
          </Col>
        </Row>               
      </React.Fragment>
    )
  }
}
