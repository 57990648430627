import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
	Container,
  FormGroup,
  Label,
  Button,
  Form,
  Input,
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import * as url from "../../../helpers/url_helper"
import {LOOKSEE_API_URL} from "../../../helpers/api_helper";
import $ from "jquery"

class LookseeConfigEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      field: "",
      value: "",
      _id: ""
    }
  }
  updateItem = (e) => {
    // e.preventDefault();
    console.log(e);
    this.setState({
      field: e.field,
      value: e.value,
      _id: e._id
    })
  }
  	componentDidMount(){
		const { data } = this.props.location
		console.log(data)
		const {field, value, _id } = data;
		this.setState({
			field,
			value,
			_id
		})
	}
	handleValidSubmit = (e) => {
		e.preventDefault();
		const data = this.state;
		const { field, value, _id } = data;
		const BToken = localStorage.getItem("gameAuth");
		const BearerToken = JSON.parse(BToken);

		fetch(`${LOOKSEE_API_URL}${url.PUT_DASHBOARD_LOOKSEE_CONFIG}/${_id}`, {
			method: 'put',
			headers: {
				'Authorization': `${BearerToken}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				field,
				value,
			})
		})
		.then(res => res.json())
		.then(data => {
			const {
				status,
				entity
			} = data ;
			if(status) {
				this.props.history.push("/looksee-config-list")
			}
		})
	}

	handleInputChange = (e) => {
		this.setState({
			[e.target.name] : e.target.value
		})
	}


  render() {
	  const {field, value, _id } = this.state;
    return (
      <React.Fragment>
		  <div className="page-content">
          <MetaTags>
            <title>Game | AP - Looksee</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={("Game Config")}
              breadcrumbItem={("Edit Config")}
            />
        <Col md={12}>
          <Card>
            <CardBody>
              	<CardTitle className="h4"></CardTitle>

				  <Form onSubmit={this.handleValidSubmit}>
						<FormGroup className="row mb-4">
						<Label
							htmlFor="horizontal-field-Input"
							className="col-sm-1 col-form-label text-md-right"
							style={{textAlign: "center"}}
						>
							Field
						</Label>
						<Col sm={6}>
							<Input
							value={field}
							type="text"
							name="field"
							className="form-control"
							id="horizontal-field-Input"
							onChange={this.handleInputChange}
							readOnly
							/>
						</Col>
						</FormGroup>
						<FormGroup className="row mb-4">
						<Label
							htmlFor="horizontal-value-Input"
							className="col-sm-1 col-form-label text-md-right"
							style={{textAlign: "center"}}
						>
							Value
						</Label>
						<Col sm={6}>
							<Input
							value={value}
							type="text"
							name="value"
							className="form-control"
							id="horizontal-value-Input"
							onChange={this.handleInputChange}
							required
							/>
						</Col>
						</FormGroup>

						<FormGroup className="row justify-content-end">
						<Col sm={11}>
							<div>
							<Button
								type="submit"
								color="primary"
								className="w-md"
							>
								Update
							</Button> &nbsp; &nbsp; &nbsp; <span className="loading"></span>
							</div>
						</Col>
						</FormGroup>
					</Form>
            </CardBody>
          </Card>
        </Col>

		</Container>
        </div>
	  </React.Fragment>
    )
  }
}

LookseeConfigEdit.propTypes = {
	t: PropTypes.any
}
export default LookseeConfigEdit
