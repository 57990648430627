import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"

import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment'
import $ from 'jquery'
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import { Calendar, DateRangePicker } from 'react-date-range';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import Looksee from "./Looksee"
import Subscribers from "./Subscribers"
import Users from "./Users"

import * as url from "../../../helpers/url_helper"
import {LOOKSEE_API_URL} from "../../../helpers/api_helper";


class LookseeReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      start: moment().subtract(-1, 'days'),
      end: moment(),
      UsersData: [],
      SubscribersData: [],
      TotalLooksee: 0, 
      basic: true,
    }       
  }
 

  render() {
    const { selectedGroup, basic } = this.state
    const { start, end, UsersData, SubscribersData, TotalLooksee, loading  } = this.state;

    const handleCallback =(start, end) => {

      let game_name = selectedGroup;
      console.log(game_name);
      this.setState({ start, end });
      let start_date = start.format('MM/DD/YYYY');
      let end_date = end.format('MM/DD/YYYY');
      $(".loadingIcon").text("Loading ...").css('color', 'red');

      const BToken = localStorage.getItem("gameAuth");
      const BearerToken = JSON.parse(BToken);
	  
      fetch(`${LOOKSEE_API_URL}${url.GET_DASHBOARD_LOOKSEE_DATE_RANGE_INFO}?start=${start_date}&end=${end_date}`, {
        method: 'get',
        headers: {
        'Authorization': `${BearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(res => {
        const {
          status,
          totalUser,
          userSubscription,
          looksee
        } = res ; 

        $(".loadingIcon").text("");
        this.setState({ 
          UsersData: [...totalUser],
          SubscribersData: [...userSubscription],
          TotalLooksee: looksee, 
          loading: true
        })

      }).catch((err) => {
        console.log(err);
      });
    };
  
    const handleApply = (event, picker) => {
      picker.element.val(
        picker.startDate.format('MM/DD/YYYY') +
          ' - ' +
          picker.endDate.format('MM/DD/YYYY')
      );
    };
    const handleCancel = (event, picker) => {
      picker.element.val('');
    };
     
    const label = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');
    

    return (
      <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Admin Panel | Looksee Report</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Admin Panel" breadcrumbItem="Looksee Report" />
            
            <Row>
              <Card>
                <CardBody>
                  <Form>                      
                      <Col lg="6">

                        <FormGroup className="mb-8">
                          <Label>Select Date Range <span className="loadingIcon"></span></Label>
                          <InputGroup>
                          <DateRangePicker
                            initialSettings={{
                              autoUpdateInput: false,
                              startDate: start.toDate(),
                              endDate: end.toDate(),
                              ranges: {
                                // "No Date": "",
                                Today: [moment().toDate(), moment().toDate()],
                                Yesterday: [
                                  moment().subtract(1, 'days').toDate(),
                                  moment().subtract(1, 'days').toDate(),
                                ],
                                'Last 7 Days': [
                                  moment().subtract(6, 'days').toDate(),
                                  moment().toDate(),
                                ],
                                'Last 30 Days': [
                                  moment().subtract(29, 'days').toDate(),
                                  moment().toDate(),
                                ],
                                'This Month': [
                                  moment().startOf('month').toDate(),
                                  moment().endOf('month').toDate(),
                                ],
                                'Last Month': [
                                  moment().subtract(1, 'month').startOf('month').toDate(),
                                  moment().subtract(1, 'month').endOf('month').toDate(),
                                ],
                              },
                            }}
                            
                            onApply={handleApply}
                            onCancel={handleCancel}
                            onCallback={handleCallback}
                            > 
                            <div
                              id="reportrange"
                              className="col-4"
                              style={{
                                background: '#fff',
                                cursor: 'pointer',
                                padding: '5px 10px',
                                border: '1px solid #ccc',
                                width: '100%',
                              }}
                            >
                              <i className="fa fa-calendar"></i>&nbsp;
                              <span>{(loading)? label: ""}</span> <i className="fa fa-caret-down"></i>
                            </div>
                            </DateRangePicker>            
                          </InputGroup>
                        </FormGroup>
                      </Col>
                  </Form>
                </CardBody>
              </Card>
            </Row>
            
            { (loading) && <Row>
              <Col xl={6}> 
                <Users user={UsersData} />
              </Col>
              <Col xl={6}> 
                <Subscribers subscribers={SubscribersData}/>
              </Col>
              <Col xl={6}> 
                <Looksee total={TotalLooksee} />
              </Col>
              </Row>
            }
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default LookseeReport
