import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
	Container,
  FormGroup,
  Label,
  Button,
  Form,
  Input,
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import * as url from "../../helpers/url_helper"
import {API_URL} from "../../helpers/api_helper";
import $ from "jquery"

class AppVersionEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      field: "",
      value: "",
	  _id: "",
	  game_name: "",
	  os_name: ''
    }
  }
  updateItem = (e) => {
    // e.preventDefault();
    console.log(e);
    this.setState({
      field: e.field,
      value: e.value,
	  _id: e._id,
	  game_name: e.game_name
    })
  }
  	componentDidMount(){
		const { data } = this.props.location
		console.log(data);
		  const {field, value, game_name, _id, os_name } = data;
		  this.setState({
			field,
			value,
			game_name,
			os_name,
			_id
		})
	}
	handleValidSubmit = (e) => {
		e.preventDefault();
		const data = this.state;
		const { field, value, game_name, _id, os_name } = data;
		console.log({field, value, _id})
		const BToken = localStorage.getItem("gameAuth");
		const BearerToken = JSON.parse(BToken);

		fetch(`${API_URL}${url.PUT_DASHBOARD_UPDATE_APP_VERSION}/${_id}`, {
			method: 'put',
			headers: {
				'Authorization': `${BearerToken}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				field,
				value,
			})
		})
		.then(res => res.json())
		.then(data => {
			const {
				status,
				entity
			} = data ;
			if(status) {
				if(game_name === 'Mafia') {
					this.props.history.push(`/mafia-app-version/${os_name}`)
				} else if(game_name === 'Werewolf') {
					this.props.history.push(`/werewolf-app-version/${os_name}`)
				} else if(game_name === 'Resistance') {
					this.props.history.push(`/resistance-app-version/${os_name}`)
				} else if(game_name === 'Avalon') {
					this.props.history.push(`/avalon-app-version/${os_name}`)
				} else if(game_name === 'Enterprise') {
					this.props.history.push(`/enterprise-app-version/${os_name}`)
				}	
			}
		})
	}

	handleInputChange = (e) => {
		this.setState({
			[e.target.name] : e.target.value
		})
	}


  render() {
	  const {field, value, game_name, _id, os_name } = this.state;
    return (
      <React.Fragment>
		  <div className="page-content">
          <MetaTags>
            <title>Game | AP - App Version</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={("App Version")}
              breadcrumbItem={("Edit App Version")}
            />
        <Col md={12}>
          <Card>
            <CardBody>
              	<CardTitle className="h4"></CardTitle>

				  <Form onSubmit={this.handleValidSubmit}>
						<FormGroup className="row mb-4">
						<Label
							htmlFor="horizontal-field-Input"
							className="col-sm-1 col-form-label text-md-right"
							style={{textAlign: "center"}}
						>
							Field
						</Label>
						<Col sm={6}>
							<Input
							value={field}
							type="text"
							name="field"
							className="form-control"
							id="horizontal-field-Input"
							onChange={this.handleInputChange}
							readOnly={true}
							/>
						</Col>
						</FormGroup>
						<FormGroup className="row mb-4">
						<Label
							htmlFor="horizontal-value-Input"
							className="col-sm-1 col-form-label text-md-right"
							style={{textAlign: "center"}}
						>
							Value
						</Label>
						<Col sm={6}>
							<Input
							value={value}
							type="text"
							name="value"
							className="form-control"
							id="horizontal-value-Input"
							onChange={this.handleInputChange}
							required
							/>
						</Col>
						</FormGroup>

						<FormGroup className="row justify-content-end">
						<Col sm={11}>
							<div>
							<Button
								type="submit"
								color="primary"
								className="w-md"
							>
								Update
							</Button> &nbsp; &nbsp; &nbsp; <span className="loading"></span>
							</div>
						</Col>
						</FormGroup>
					</Form>
            </CardBody>
          </Card>
        </Col>

		</Container>
        </div>
	  </React.Fragment>
    )
  }
}

AppVersionEdit.propTypes = {
	t: PropTypes.any
}
export default AppVersionEdit
