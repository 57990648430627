import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  Input
} from "reactstrap"
import * as url from "../../../helpers/url_helper"
import {LOOKSEE_API_URL} from "../../../helpers/api_helper";
import { Link } from "react-router-dom"


// Pages Components
import Users from "./Users"
import Subscribers from "./Subscribers"
import FcmNotifiForm from "./FcmNotifiForm"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

class UserInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      UsersData: [],
      subscribers: []
    }
  }
  
  componentDidMount() {
    const BToken = localStorage.getItem("gameAuth");
    const BearerToken = JSON.parse(BToken);
    // console.log(BearerToken)
    fetch(`${LOOKSEE_API_URL}${url.GET_DASHBOARD_USERS_INFO}`, {
      method: 'get',
      headers: {
        'Authorization': `${BearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(data => {
      const {
        status,
        totalUser,
        userSubscription
      } = data ; 
      console.log({totalUser, userSubscription})
      this.setState({ 
        UsersData: [...totalUser],
        subscribers: [...userSubscription],
      })
  
    }).catch(function (error) {
      console.log(error);
    });    

  }


  render() {
    const {UsersData, subscribers} = this.state;
    
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Game | AP - User List</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Admin Panel")}
              breadcrumbItem={this.props.t("Looksee")}
            />

            <Row>
              <Col xl={6}> 
                <Users user={UsersData} />
              </Col>
              <Col xl={6}> 
                <Subscribers subscribers={subscribers}/>
              </Col>
            </Row>
            <Row>
              <FcmNotifiForm />
            </Row>
          </Container>
        </div>
        
      </React.Fragment>
    )
  }
}

UserInfo.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(UserInfo)
