import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"

// Game wise report
import Dashboard from "../pages/Dashboard/index"

import Mafia from "../pages/Mafia/index"
import Resistance from "../pages/Resistance/index"
import Avalon from "../pages/Avalon/index"
import Werewolf from "../pages/Werewolf/index"

import MafiaAndroid from "../pages/MafiaAndroid/index"
import ResistanceAndroid from "../pages/ResistanceAndroid/index"
import AvalonAndroid from "../pages/AvalonAndroid/index"
import WerewolfAndroid from "../pages/WerewolfAndroid/index"

import IosReport from "../pages/Report/IosReport"
import AndroidReport from "../pages/Report/AndroidReport"
import WebReport from "../pages/WebGames/Report/Report"

import MafiaConfig from "../pages/Configs/Mafia/index"
import WerewolfConfig from "../pages/Configs/Werewolf/index"
import ResistanceConfig from "../pages/Configs/Resistance/index"
import AvalonConfig from "../pages/Configs/Avalon/index"

import EnterpriseMafiaConfig from "../pages/EnterpriseConfigs/Mafia/index"
import EnterpriseWerewolfConfig from "../pages/EnterpriseConfigs/Werewolf/index"
import EnterpriseResistanceConfig from "../pages/EnterpriseConfigs/Resistance/index"
import EnterpriseAvalonConfig from "../pages/EnterpriseConfigs/Avalon/index"
import EnterpriseWebConfig from "../pages/EnterpriseConfigs/Web/index"
import EnterpriseConfigEdit from "../pages/EnterpriseConfigs/ConfigEdit"

import EnterpriseConfig from "../pages/Configs/Enterprise/index"

import configEdit from "../pages/Configs/ConfigEdit"
import MafiaAppVersion from "../pages/AppVersion/Mafia/index"
import AvalonAppVersion from "../pages/AppVersion/Avalon/index"
import ResistanceAppVersion from "../pages/AppVersion/Resistance/index"
import WerewolfAppVersion from "../pages/AppVersion/Werewolf/index"

import EnterprseAppVersion from "../pages/AppVersion/Enterprise/index"
import EnterpriseFcm from "../pages/Enterprise/index"

import AppVersionEdit from "../pages/AppVersion/AppVersionEdit"

// Looksee
import LookseeConfig from "../pages/Looksee/Config/index"
import LookseeConfigEdit from "../pages/Looksee/Config/ConfigEdit"
import LookseePolicyEdit from "../pages/Looksee/Config/LookseeEdit"
import LookseePromo from "../pages/Looksee/LookseePromo/index"

import UserInfo from "../pages/Looksee/Users/index"
import LookseeReport from "../pages/Looksee/Report/index"

// For Solo 
import SoloUserInfo  from "../pages/Solo/Users/index"
import SoloReport from "../pages/Solo/Report/index"
import SoloConfig from "../pages/Solo/Config/index"
import SoloConfigEdit from "../pages/Solo/Config/ConfigEdit"
import SoloPolicyEdit from "../pages/Solo/Config/LookseeEdit"

import WerewolfPromo from "../pages/Werewolf/WerewolfPromo/index"
import MafiaPromo from "../pages/Mafia/MafiaPromo/index"
import AvalonPromo from "../pages/Avalon/AvalonPromo/index"
import ResistancePromo from "../pages/Resistance/ResistancePromo/index"


// Web Games
import WebGameDashboard from "../pages/WebGames/Dashboard/index"

import WebResistance from "../pages/WebGames/Resistance/index"
import WebAvalon from "../pages/WebGames/Avalon/index"
import WebMafia from "../pages/WebGames/Mafia/index"
import WebWerewolf from "../pages/WebGames/Werewolf/index"

import WebconfigEdit from "../pages/WebConfigs/ConfigEdit"
import WebMafiaConfig from "../pages/WebConfigs/Mafia/index"
import WebWerewolfConfig from "../pages/WebConfigs/Werewolf/index"
import WebResistanceConfig from "../pages/WebConfigs/Resistance/index"
import WebAvalonConfig from "../pages/WebConfigs/Avalon/index"
import EnterpriseWebReport from "pages/Report/EnterpriseWebReport"
import EnterpriseiOSReport from "pages/Report/EnterpriseiOSReport"
import EnterpriseAndroidReport from "pages/Report/EnterpriseAndroidReport"

// For Enterprise
import EnterpriseIOS from "../pages/EnterpriseGames/index"
import EnterpriseAndroid from "../pages/EnterpriseGames/enterpriseAndroid"
import EnterpriseWeb from "../pages/EnterpriseGames/enterpriseWeb"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard},
  
  { path: "/mafia", component: Mafia },
  { path: "/resistance", component: Resistance },
  { path: "/avalon", component: Avalon },
  { path: "/werewolf", component: Werewolf },

  { path: "/mafia-android", component: MafiaAndroid },
  { path: "/resistance-android", component: ResistanceAndroid },
  { path: "/avalon-android", component: AvalonAndroid },
  { path: "/werewolf-android", component: WerewolfAndroid},
  
  { path: "/ios-report", component: IosReport },
  { path: "/android-report", component: AndroidReport },
  { path: "/enterprise/web-report", component: EnterpriseWebReport },
  { path: "/enterprise/ios-report", component: EnterpriseiOSReport},
  { path: "/enterprise/android-report", component: EnterpriseAndroidReport },
  { path: "/enterprise-fcm-push", component: EnterpriseFcm },
  
  { path: "/mafia-config", component: MafiaConfig },
  { path: "/werewolf-config", component: WerewolfConfig },
  { path: "/resistance-config", component: ResistanceConfig },
  { path: "/avalon-config", component: AvalonConfig },
  { path: "/enterprise-config", component: EnterpriseConfig },

  { path: "/enterprise/mafia-config", component: EnterpriseMafiaConfig },
  { path: "/enterprise/werewolf-config", component: EnterpriseWerewolfConfig },
  { path: "/enterprise/resistance-config", component: EnterpriseResistanceConfig },
  { path: "/enterprise/avalon-config", component: EnterpriseAvalonConfig },
  { path: "/enterprise/web-config", component: EnterpriseWebConfig },

  { path: "/config-edit", component: configEdit },
  { path: "/enterprise/config-edit", component: EnterpriseConfigEdit },
  
  { path: "/mafia-app-version/:os_name", component: MafiaAppVersion },
  { path: "/werewolf-app-version/:os_name", component: WerewolfAppVersion },
  { path: "/resistance-app-version/:os_name", component: ResistanceAppVersion },
  { path: "/avalon-app-version/:os_name", component: AvalonAppVersion },
  { path: "/enterprise-app-version/:os_name", component: EnterprseAppVersion },

  { path: "/app-version-edit", component: AppVersionEdit},
  { path: "/looksee-config-list", component: LookseeConfig },
  { path: "/looksee-config-edit", component: LookseeConfigEdit},
  { path: "/looksee-policy-edit", component: LookseePolicyEdit},
  { path: "/looksee-users-info", component: UserInfo },
  { path: "/looksee-report", component: LookseeReport },
  { path: "/looksee-promo", component: LookseePromo},

  { path: "/solo-users-info", component: SoloUserInfo },
  { path: "/solo-report", component: SoloReport },
  { path: "/solo-config-list", component: SoloConfig},
  { path: "/solo-config-edit", component: SoloConfigEdit},
  { path: "/solo-policy-edit", component: SoloPolicyEdit},
  
  { path: "/werewolf-promo", component: WerewolfPromo },
  { path: "/mafia-promo", component: MafiaPromo },
  { path: "/avalon-promo", component: AvalonPromo },
  { path: "/resistance-promo", component: ResistancePromo },

  // Web Game Route
  { path: "/web-dashboard", component: WebGameDashboard},
  
  { path: "/web-mafia", component: WebMafia },
  { path: "/web-werewolf", component: WebWerewolf },
  { path: "/web-resistance", component: WebResistance },
  { path: "/web-avalon", component: WebAvalon },

  { path: "/web-date-range", component: WebReport },
  { path: "/web-mafia-config", component: WebMafiaConfig },
  { path: "/web-werewolf-config", component: WebWerewolfConfig },
  { path: "/web-resistance-config", component: WebResistanceConfig },
  { path: "/web-avalon-config", component: WebAvalonConfig },
  { path: "/web-config-edit", component: WebconfigEdit },

  // Enterprise Mobile
  { path: "/enterprise-ios", component: EnterpriseIOS },
  { path: "/enterprise-android", component: EnterpriseAndroid },
  { path: "/enterprise-web", component: EnterpriseWeb },
  
]

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/profile", component: Register },
  
  { path: "/", exact: true, component: () =>(localStorage.getItem("authUser"))? <Redirect to="/dashboard" /> : <Redirect to="/login" />},
]

export { authProtectedRoutes, publicRoutes }
