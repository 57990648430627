import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
} from "reactstrap"


export default class VideoAds extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
    this.toggledropdown = this.toggledropdown.bind(this)
  }

  toggledropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }
  render() {
    const {total, rewarded, postgame} = this.props;
    return (
      <React.Fragment>                
        <Col xl={12}>          
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <h5 className="card-title mb-3 me-2">Total Ads</h5>
              </div>

              <div className="d-flex flex-wrap">
                <div>
                    <h4 className="mb-3">{total}</h4>
                </div>
                <div className="ms-auto align-self-end">
                  <i className="bx bx-video display-6 text-light"></i>
                </div>
              </div>
            </CardBody>
          </Card>          
        </Col>
        <Col xl={12}>          
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <h5 className="card-title mb-3 me-2">Post-Game Ads</h5>
              </div>

              <div className="d-flex flex-wrap">
                <div>
                    <h4 className="mb-3">{postgame}</h4>
                </div>
                <div className="ms-auto align-self-end">
                  <i className="bx bx-video display-6 text-light"></i>
                </div>
              </div>
            </CardBody>
          </Card>          
        </Col>
        <Col xl={12}>          
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <h5 className="card-title mb-3 me-2">Rewarded Ads</h5>
              </div>

              <div className="d-flex flex-wrap">
                <div>
                    <h4 className="mb-3">{rewarded}</h4>
                </div>
                <div className="ms-auto align-self-end">
                  <i className="bx bx-video display-6 text-light"></i>
                </div>
              </div>
            </CardBody>
          </Card>          
        </Col>
      </React.Fragment>
    )
  }
}
