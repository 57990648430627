import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
} from "reactstrap"
import * as url from "../../../helpers/url_helper"
import {API_URL} from "../../../helpers/api_helper";
import { Link } from "react-router-dom"

//import Charts

// Pages Components
import ConfigList from "./ConfigList"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

class MafiaConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_loading: false,
      game_config: []
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }
  componentWillMount() {
    
  }
  
  componentDidMount() {
    const BToken = localStorage.getItem("gameAuth");
    const BearerToken = JSON.parse(BToken);
    fetch(`${API_URL}${url.GET_DASHBOARD_GAME_CONFIG}?game_name=Mafia&game_type=Individual`, {
      method: 'get',
      headers: {
        'Authorization': `${BearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(data => {
      const {
        status,
        entity
      } = data ; 
  
      this.setState({ 
        is_loading: true,
        game_config: [...entity]
      })
  
    }).catch(function (error) {
      console.log(error);
    });

  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  render() {
    const {game_config, is_loading } = this.state;
    
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Game | AP - Mafia Config</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Config")}
              breadcrumbItem={this.props.t("Mafia Config list")}
            />

            <Row>
              {
                is_loading ? (
                  <ConfigList game_config={game_config} />
                ) : (
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="text-center">
                          <div className="spinner-border m-1" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              
            </Row>
          </Container>
        </div>
        
      </React.Fragment>
    )
  }
}

MafiaConfig.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(MafiaConfig)
