import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  Input
} from "reactstrap"
import * as url from "../../../helpers/url_helper"
import {LOOKSEE_API_URL} from "../../../helpers/api_helper";
import { Link } from "react-router-dom"

//import Charts

// Pages Components
import ConfigList from "./ConfigList"
import LookseePolicyList from "./LookseePolicyList"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

class LookseeConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      config: [],
      polices: []
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }
  componentWillMount() {
    
  }
  
  componentDidMount() {
    const BToken = localStorage.getItem("gameAuth");
    const BearerToken = JSON.parse(BToken);

    Promise.all([
      fetch(`${LOOKSEE_API_URL}${url.GET_DASHBOARD_LOOKSEE_CONFIG}`, {
          method: 'get',
          headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        })
        .then(res => res.json()),
      fetch(`${LOOKSEE_API_URL}${url.GET_DASHBOARD_LOOKSEE_POLICY}`, {
        method: 'get',
        headers: {
          'Authorization': `${BearerToken}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
    ]).then(([data, looksee]) => {
      const {
        status,
        entity
      } = data ;

      const {
        entity: looksee_policy
      } = looksee ; 
  
      this.setState({ 
        config: [...entity],
        polices: [...looksee_policy]
      })
  
    }).catch( (error) => {
      console.log(error);
    });
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  render() {
    const {config, polices } = this.state;
    
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Game | AP - Looksee Config</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Config")}
              breadcrumbItem={this.props.t("Looksee Config list")}
            />

            <Row>
              <ConfigList config={config} />
            </Row>

            <Row>
              <LookseePolicyList policy={polices}/>
            </Row>

          </Container>
        </div>
        
      </React.Fragment>
    )
  }
}

LookseeConfig.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(LookseeConfig)
