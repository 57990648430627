import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"

import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment'
import $ from 'jquery'
import "flatpickr/dist/themes/material_blue.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import { Calendar, DateRangePicker } from 'react-date-range';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import TotalNewUsers from "./TotalNewUsers"
import TotalGamePlayed from "./TotalGamePlayed"
import TotalSubscribes from "./TotalSubscribes"
import InAppPurchase from "./InAppPurchase"
import VideoAds from "./VideoAds"
import GameHost from "./GameHost"
import TotalGames from "./TotalGames"
import IncompleteGames from "./IncompleteGames"
import GameList from "./GameList"

import * as url from "../../helpers/url_helper"
import {API_URL} from "../../helpers/api_helper";


class IosReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      start: moment().subtract(-1, 'days'),
      end: moment(),
      totalUser: 0,
      totalSubscribers: 0,
      TotalAds: 0, 
      RewardedAds: 0, 
      PostGameAds: 0,
      inAppPurchaseCounter: 0,
      totalGamePlayer: 0,
      gamePlayersCount: 0,
      successGameCount: 0,
      unsuccessGameCount: 0,
      hostGameCount: 0,
      game_list:[],
      selectedGroup: 'allGame',
      basic: true,
    }       
  }

  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup: selectedGroup.target.value })
  }
  

  render() {
    const { selectedGroup, basic } = this.state
    const { start, end } = this.state;
    const { 
      TotalAds,
      RewardedAds,
      PostGameAds,
      totalSubscribers,
      totalUser,
      inAppPurchaseCounter,
      totalGamePlayer,
      gamePlayersCount,
      successGameCount,
      unsuccessGameCount,
      hostGameCount,
      game_list,
      loading 
    } = this.state;

    const handleCallback =(start, end) => {

      let game_name = selectedGroup;
      
      this.setState({ start, end });
      let start_date = start.format('MM/DD/YYYY');
      let end_date = end.format('MM/DD/YYYY');
      $(".loadingIcon").text("Loading ...").css('color', 'red');

      const BToken = localStorage.getItem("gameAuth");
      const BearerToken = JSON.parse(BToken);
      if(game_name === "allGame") {
        Promise.all([
          fetch(`${API_URL}${url.MAFIA_DASHBOARD_DATE_RANGE_INFO}?os_name=IOS&start=${start_date}&end=${end_date}`, {
              method: 'get',
              headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }
            }).then(value => value.json()),
          fetch(`${API_URL}${url.RESISTANCE_DASHBOARD_DATE_RANGE_INFO}?os_name=IOS&start=${start_date}&end=${end_date}`, {
              method: 'get',
              headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }
            }).then(value => value.json()),
          fetch(`${API_URL}${url.AVALON_DASHBOARD_DATE_RANGE_INFO}?os_name=IOS&start=${start_date}&end=${end_date}`, {
              method: 'get',
              headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }
          }).then(value => value.json()),
          fetch(`${API_URL}${url.WEREWOLF_DASHBOARD_DATE_RANGE_INFO}?os_name=IOS&start=${start_date}&end=${end_date}`, {
            method: 'get',
            headers: {
              'Authorization': `${BearerToken}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
            }).then(value => value.json()),
          fetch(`${API_URL}${url.GET_DASHBOARD_DATE_RANGE_INFO}?os_name=IOS&start=${start_date}&end=${end_date}`, {
              method: 'get',
              headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }
            })
            .then(res => res.json())
        ]).then((res) => {
          const [ mafiaInfo, resistanceInfo, avalonInfo, werewolfInfo, DashboardInfo] = res;
            let {
              mafia_gamePlayersCount,
              mafia_successGameCount,
              mafia_unsuccessGameCount,
              mafia_joinGameCount,
              mafia_hostGameCount,
            } = mafiaInfo;
            let {
              resistance_gamePlayersCount,
              resistance_successGameCount,
              resistance_unsuccessGameCount,
              resistance_joinGameCount,
              resistance_hostGameCount,
            } = resistanceInfo;
            let {
              avalon_gamePlayersCount,
              avalon_successGameCount,
              avalon_unsuccessGameCount,
              avalon_joinGameCount,
              avalon_hostGameCount,
            } = avalonInfo;
            let {
              werewolf_gamePlayersCount,
              werewolf_successGameCount,
              werewolf_unsuccessGameCount,
              werewolf_joinGameCount,
              werewolf_hostGameCount,
            } = werewolfInfo;
  
            let g_gamePlayersCount = parseInt(mafia_gamePlayersCount) + parseInt( resistance_gamePlayersCount) + parseInt( avalon_gamePlayersCount) + parseInt( werewolf_gamePlayersCount);
            let g_successGameCount = parseInt(mafia_successGameCount) + parseInt( resistance_successGameCount) + parseInt( avalon_successGameCount) + parseInt( werewolf_successGameCount);
            let g_unsuccessGameCount = parseInt(mafia_unsuccessGameCount) + parseInt( resistance_unsuccessGameCount) + parseInt( avalon_unsuccessGameCount) + parseInt( werewolf_unsuccessGameCount);
            let g_hostGameCount = parseInt(mafia_hostGameCount) + parseInt( resistance_hostGameCount) + parseInt( avalon_hostGameCount) + parseInt( werewolf_hostGameCount);
            
            const {
              status,
              totalUser,
              totalSubscribers,
              TotalAds, 
              RewardedAds, 
              PostGameAds,
              inAppPurchaseCounter
            } = DashboardInfo ; 
  
            $(".loadingIcon").text("");
            this.setState({ 
              totalGamePlayer,
              TotalAds, 
              RewardedAds, 
              PostGameAds,
              totalSubscribers,
              totalUser,
              inAppPurchaseCounter,
  
              gamePlayersCount: g_gamePlayersCount,
              successGameCount: g_successGameCount,
              unsuccessGameCount: g_unsuccessGameCount,
              hostGameCount: g_hostGameCount,
              loading: true
            })
        }).catch((err) => {
            console.log(err);
        });
      } else if(game_name === "Mafia") {
        Promise.all([
          fetch(`${API_URL}${url.MAFIA_DASHBOARD_DATE_RANGE_INFO}?os_name=IOS&start=${start_date}&end=${end_date}`, {
            method: 'get',
            headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
          }).then(value => value.json()),	  
          fetch(`${API_URL}${url.GET_DASHBOARD_DATE_RANGE_INFO}?os_name=IOS&game_name=${game_name}&start=${start_date}&end=${end_date}`, {
            method: 'get',
            headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
        ]).then(([ mafiaInfo, DashboardInfo]) => {
          let {
            mafia_gamePlayersCount,
            mafia_successGameCount,
            mafia_unsuccessGameCount,
            mafia_joinGameCount,
            mafia_hostGameCount,
            mafia_game_list
          } = mafiaInfo;
          
          const {
            status,
            totalUser,
            totalSubscribers,
            TotalAds, 
            RewardedAds, 
            PostGameAds,
            inAppPurchaseCounter
          } = DashboardInfo ; 
      
          $(".loadingIcon").text("");
          this.setState({ 
            totalGamePlayer,
            TotalAds, 
            RewardedAds, 
            PostGameAds,
            totalSubscribers,
            totalUser,
            inAppPurchaseCounter,
      
            gamePlayersCount: mafia_gamePlayersCount,
            successGameCount: mafia_successGameCount,
            unsuccessGameCount: mafia_unsuccessGameCount,
            hostGameCount: mafia_hostGameCount,
            game_list: [...mafia_game_list],
            loading: true
          })
        }).catch((err) => {
          console.log(err);
        });
      } else if (game_name === "Avalon") {
        Promise.all([          
          fetch(`${API_URL}${url.AVALON_DASHBOARD_DATE_RANGE_INFO}?os_name=IOS&start=${start_date}&end=${end_date}`, {
            method: 'get',
            headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
          }).then(value => value.json()),
          fetch(`${API_URL}${url.GET_DASHBOARD_DATE_RANGE_INFO}?os_name=IOS&game_name=${game_name}&start=${start_date}&end=${end_date}`, {
            method: 'get',
            headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
        ]).then(([avalonInfo, DashboardInfo]) => {
          let {
            avalon_gamePlayersCount,
            avalon_successGameCount,
            avalon_unsuccessGameCount,
            avalon_hostGameCount,
            avalon_game_list
          } = avalonInfo;
          
          const {
            status,
            totalUser,
            totalSubscribers,
            TotalAds, 
            RewardedAds, 
            PostGameAds,
            inAppPurchaseCounter
          } = DashboardInfo ; 
      
          $(".loadingIcon").text("");
          this.setState({ 
            totalGamePlayer,
            TotalAds, 
            RewardedAds, 
            PostGameAds,
            totalSubscribers,
            totalUser,
            inAppPurchaseCounter,
      
            gamePlayersCount: avalon_gamePlayersCount,
            successGameCount: avalon_successGameCount,
            unsuccessGameCount: avalon_unsuccessGameCount,
            hostGameCount: avalon_hostGameCount,
            game_list: [...avalon_game_list],
            loading: true
          })
        }).catch((err) => {
          console.log(err);
        });
      } else if(game_name === "Resistance") {
        Promise.all([		
          fetch(`${API_URL}${url.RESISTANCE_DASHBOARD_DATE_RANGE_INFO}?os_name=IOS&start=${start_date}&end=${end_date}`, {
            method: 'get',
            headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
          }).then(value => value.json()),
          fetch(`${API_URL}${url.GET_DASHBOARD_DATE_RANGE_INFO}?os_name=IOS&game_name=${game_name}&start=${start_date}&end=${end_date}`, {
            method: 'get',
            headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
        ]).then(( [ resistanceInfo,DashboardInfo]) => {
          
          let {
            resistance_gamePlayersCount,
            resistance_successGameCount,
            resistance_unsuccessGameCount,
            resistance_joinGameCount,
            resistance_hostGameCount,
            resistance_game_list
          } = resistanceInfo;
          const {
            status,
            totalUser,
            totalSubscribers,
            TotalAds, 
            RewardedAds, 
            PostGameAds,
            inAppPurchaseCounter
          } = DashboardInfo ; 
      
          $(".loadingIcon").text("");
          this.setState({ 
            totalGamePlayer,
            TotalAds, 
            RewardedAds, 
            PostGameAds,
            totalSubscribers,
            totalUser,
            inAppPurchaseCounter,
      
            gamePlayersCount: resistance_gamePlayersCount,
            successGameCount: resistance_successGameCount,
            unsuccessGameCount: resistance_unsuccessGameCount,
            hostGameCount: resistance_hostGameCount,
            game_list: [...resistance_game_list],
            loading: true
          })
        }).catch((err) => {
          console.log(err);
        });
      } else if(game_name === "Werewolf") {
        Promise.all([          
          fetch(`${API_URL}${url.WEREWOLF_DASHBOARD_DATE_RANGE_INFO}?os_name=IOS&start=${start_date}&end=${end_date}`, {
          method: 'get',
          headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
          }).then(value => value.json()),
          fetch(`${API_URL}${url.GET_DASHBOARD_DATE_RANGE_INFO}?os_name=IOS&game_name=${game_name}&start=${start_date}&end=${end_date}`, {
            method: 'get',
            headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
        ]).then(([ werewolfInfo, DashboardInfo] ) => {
          
          let {
            werewolf_gamePlayersCount,
            werewolf_successGameCount,
            werewolf_unsuccessGameCount,
            werewolf_joinGameCount,
            werewolf_hostGameCount,
            werewolf_game_list
          } = werewolfInfo;
      
          const {
            status,
            totalUser,
            totalSubscribers,
            TotalAds, 
            RewardedAds, 
            PostGameAds,
            inAppPurchaseCounter
          } = DashboardInfo ; 
      
          $(".loadingIcon").text("");
          this.setState({ 
            totalGamePlayer,
            TotalAds, 
            RewardedAds, 
            PostGameAds,
            totalSubscribers,
            totalUser,
            inAppPurchaseCounter,
      
            gamePlayersCount: werewolf_gamePlayersCount,
            successGameCount: werewolf_successGameCount,
            unsuccessGameCount: werewolf_unsuccessGameCount,
            hostGameCount: werewolf_hostGameCount,
            game_list: [...werewolf_game_list],
            loading: true
          })
        }).catch((err) => {
          console.log(err);
        });
      }
    };

    const handleApply = (event, picker) => {
      picker.element.val(
        picker.startDate.format('MM/DD/YYYY') +
          ' - ' +
          picker.endDate.format('MM/DD/YYYY')
      );
    };
    const handleCancel = (event, picker) => {
      picker.element.val('');
    };
     
    const label = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');
    

    return (
      <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Admin Panel | Date Range</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Admin Panel" breadcrumbItem="iOS Date Range Report" />
            
            <Row>
              <Card>
                <CardBody>
                  <Form>
                      <Col lg="12">
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              name="myCheckbox"
                              type="radio"
                              value="allGame"
                              id="allGame"
                              onClick={this.handleSelectGroup}
                              defaultChecked={true}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allGame"
                            >
                              All Games
                            </label>
                          </div>
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              name="myCheckbox"
                              type="radio"
                              value="Mafia"
                              id="mafia"
                              onClick={this.handleSelectGroup}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mafia"
                            >
                              Mafia
                            </label>
                          </div>                          
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              name="myCheckbox"
                              type="radio"
                              value="Werewolf"
                              id="werewolf"
                              onClick={this.handleSelectGroup}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="werewolf"
                            >
                              Werewolf
                            </label>
                          </div>
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              name="myCheckbox"
                              type="radio"
                              value="Resistance"
                              id="resistance"
                              onClick={this.handleSelectGroup}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="resistance"
                            >
                              Resistance
                            </label>
                          </div>
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              name="myCheckbox"
                              type="radio"
                              value="Avalon"
                              id="Avalon"
                              onClick={this.handleSelectGroup}
                              
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Avalon"
                            >
                              Avalon
                            </label>
                          </div>                          
                      </Col>
                      <Col lg="6">

                        <FormGroup className="mb-8">
                          <Label>Select Date Range <span className="loadingIcon"></span></Label>
                          <InputGroup>
                          <DateRangePicker
                            initialSettings={{
                              autoUpdateInput: false,
                              startDate: start.toDate(),
                              endDate: end.toDate(),
                              ranges: {
                                // "No Date": "",
                                Today: [moment().toDate(), moment().toDate()],
                                Yesterday: [
                                  moment().subtract(1, 'days').toDate(),
                                  moment().subtract(1, 'days').toDate(),
                                ],
                                'Last 7 Days': [
                                  moment().subtract(6, 'days').toDate(),
                                  moment().toDate(),
                                ],
                                'Last 30 Days': [
                                  moment().subtract(29, 'days').toDate(),
                                  moment().toDate(),
                                ],
                                'This Month': [
                                  moment().startOf('month').toDate(),
                                  moment().endOf('month').toDate(),
                                ],
                                'Last Month': [
                                  moment().subtract(1, 'month').startOf('month').toDate(),
                                  moment().subtract(1, 'month').endOf('month').toDate(),
                                ],
                              },
                            }}
                            
                            onApply={handleApply}
                            onCancel={handleCancel}
                            onCallback={handleCallback}
                            > 
                            <div
                              id="reportrange"
                              className="col-4"
                              style={{
                                background: '#fff',
                                cursor: 'pointer',
                                padding: '5px 10px',
                                border: '1px solid #ccc',
                                width: '100%',
                              }}
                            >
                              <i className="fa fa-calendar"></i>&nbsp;
                              <span>{(loading)? label: ""}</span> <i className="fa fa-caret-down"></i>
                            </div>
                            </DateRangePicker>            
                          </InputGroup>
                        </FormGroup>
                      </Col>
                  </Form>
                </CardBody>
              </Card>
            </Row>
            
            { (loading) && <Row>
                <Col xl={4}> 
                  <TotalNewUsers user={totalUser} />
                  { (selectedGroup === "allGame") && <TotalSubscribes subscribes={totalSubscribers}/> }
                  { (selectedGroup !== "allGame") && <GameList game_list={game_list} type="ios"/> }
                </Col>
                <Col xl={4}> 
                  <TotalGames user={successGameCount} />
                  <TotalGamePlayed totalPlayers = {gamePlayersCount}/>
                  <GameHost user={hostGameCount}/>
                  <IncompleteGames user={unsuccessGameCount} />
                  <InAppPurchase inAppCounter={inAppPurchaseCounter}/>
                </Col>
                <Col xl={4}> 
                  <VideoAds total={TotalAds} rewarded={RewardedAds} postgame={PostGameAds} />
                </Col>
              </Row>
            }
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default IosReport
