import React, { Component } from "react"
import {
  Card,
  CardBody,
  Col,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Form,
  Input,
  Alert
} from "reactstrap"
import * as url from "../../../helpers/url_helper"
import {LOOKSEE_API_URL} from "../../../helpers/api_helper";
import $ from "jquery"
import Swal from "sweetalert2"

export default class FcmNotifiForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
		type: "Test",
		title: "",
		body: "",
      	dropdownOpen: false,
    }
    this.toggledropdown = this.toggledropdown.bind(this)
  }

  	handleValidSubmit = (e) => {
		e.preventDefault();
		$(".loading").text(` Loading ...`).css('color', 'red')
		const BToken = localStorage.getItem("gameAuth");
		const BearerToken = JSON.parse(BToken);

		const data = this.state;
		const { type, title, body } = data;
		if(type ==='Test') {
			fetch(`${LOOKSEE_API_URL}${url.POST_DASHBOARD_LOOKSEE_FCM_SEND_NOTIFICATION}`, {
				method: 'post',
				headers: {
					'Authorization': `${BearerToken}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					type,
					title,
					body
				})
			})
			.then(res => res.json())
			.then(data => {
				const {
					status,
					message
				} = data ; 
				$(".success_message").text(` ${message}`).css('color', 'green').fadeIn('slow').delay(5000).hide(1);;
				$(".loading").text(``)
				this.setState({
					type: "Test",
					title: "",
					body: "",
				})
			})
		} else {
			Swal.fire({
				title: 'Are you sure?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, send it!',
				cancelButtonText: 'No, keep it'
			  }).then((result) => {
				if (result.value) {
					// console.log(BearerToken)
					fetch(`${LOOKSEE_API_URL}${url.POST_DASHBOARD_LOOKSEE_FCM_SEND_NOTIFICATION}`, {
						method: 'post',
						headers: {
							'Authorization': `${BearerToken}`,
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							type,
							title,
							body
						})
					})
					.then(res => res.json())
					.then(data => {
						const {
							status,
							message
						} = data ; 
						$(".success_message").text(` ${message}`).css('color', 'green').fadeIn('slow').delay(5000).hide(1);;
						$(".loading").text(``)
						this.setState({
							type: "Test",
							title: "",
							body: "",
						})
					})				
				  
				// For more information about handling dismissals please visit
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					$(".loading").text(``)
				}
			})
		}
  	}

  	handleInputChange = (e) => {
		// e.preventDefault();
		this.setState({
			[e.target.name] : e.target.value
		})
	}

	handleInputClick = (e) => {
		this.setState({
			[e.target.name] : e.target.value
		})
  	}
	  

  toggledropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }
  render() {
	  const {type, title, body} = this.state;
    return (
      <React.Fragment>        
	
		<Col lg={12}>
			<Card>
				<CardBody>
				<CardTitle className="h4 mb-4">
					FCM Push Notification
					<span className="success_message"></span>
				</CardTitle>

				<Form onSubmit={this.handleValidSubmit}>

					<FormGroup className="mb-3">
						<Label
							htmlFor="horizontal-title-Input"
							className="col-sm-1 col-form-label"
						>
							
						</Label>
						<div className="form-check form-check-inline">
							<Input
							type="radio"
							id="customRadioInline1"
							name="type"
							value="Test"
							className="form-check-input"
							defaultChecked={true}
							onClick={this.handleInputClick}
							/>
							<Label
							className="form-check-label"
							htmlFor="customRadioInline1"
							>
							Test
							</Label>
						</div>
						&nbsp; &nbsp; &nbsp;
						<div className="form-check form-check-inline">
							<Input
							type="radio"
							id="customRadioInline2"
							name="type"
							value="Send"
							className="form-check-input"
							onClick={this.handleInputClick}
							/>
							<Label
							className="form-check-label"
							htmlFor="customRadioInline2"
							>
							Send
							</Label>
						</div>
					</FormGroup>
					<FormGroup className="row mb-4">
					<Label
						htmlFor="horizontal-title-Input"
						className="col-sm-1 col-form-label text-md-right"
						style={{textAlign: "center"}}
					>
						Title
					</Label>
					<Col sm={9}>
						<Input
						value={title}
						type="text"
						name="title"
						className="form-control"
						id="horizontal-title-Input"
						onChange={this.handleInputChange}
						required
						/>
					</Col>
					</FormGroup>
					<FormGroup className="row mb-4">
					<Label
						htmlFor="description"
						className="col-sm-1 col-form-label" style={{textAlign: "center"}}
					>
						Body
					</Label>
					<Col sm={9}>
						<textarea
                            className="form-control"
                            id="description"
                            rows="10"
                            placeholder="Enter Description..."
							name="body"
							value={body}
                            onChange= {this.handleInputChange}
                          />
					</Col>		
					</FormGroup>
					<FormGroup className="row justify-content-end">
					<Col sm={11}>
						<div>
						<Button
							type="submit"
							color="primary"
							className="w-md"
						>
							Submit
						</Button> &nbsp; &nbsp; &nbsp; <span className="loading"></span>
						</div>
					</Col>
					</FormGroup>
				</Form>
				</CardBody>
			</Card>
		</Col>
      
	  </React.Fragment>
    )
  }
}

