import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import * as url from "../../helpers/url_helper"
import {ENTERPRISE_API_URL} from "../../helpers/api_helper";

// Pages Components
import TotalUsers from "./TotalUsers"
import TotalGamePlayers from "./TotalGamePlayers"
import FcmNotifiForm from "./FcmNotifiForm"
import InAppPurchase from "./InAppPurchase"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

class EnterpriseAndroid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      totalUsers: 0,
      totalGames: 0,
      totalGamePlayers: 0,
      totalInAppPurchases: 0,
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }
  componentWillMount() {
    const BToken = localStorage.getItem("gameAuth");
    const BearerToken = JSON.parse(BToken);
    // console.log(BearerToken)
    fetch(`${ENTERPRISE_API_URL}${url.GET_DASHBOARD_INFO}?os_name=IOS`, {
      method: 'get',
      headers: {
        'Authorization': `${BearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(data => {
      const {
        status,
        totalUsers,
        totalGames,
        totalGamePlayers,
        totalInAppPurchases,
      } = data ; 
  
      this.setState({ 
        totalUsers,
        totalGames,
        totalGamePlayers,
        totalInAppPurchases,
      })
  
    }).catch(function (error) {
      console.log(error);
    });
  }

  componentDidMount() {
    setTimeout(() => this.setState({ subscribemodal: false }), 2000);
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  render() {
    const {
      totalUsers,
      totalGames,
      totalGamePlayers,
      totalInAppPurchases,
    } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Game | AP - Enterprise-Android</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Admin Panel")}
              breadcrumbItem={this.props.t("Enterprise Android")}
            />  

            <Row>
              <Col xl={4}> 
                <TotalUsers user={totalUsers} />
              </Col>
              <Col xl={4}> 
                <TotalGamePlayers totalGames= {totalGames} totalPlayers = {totalGamePlayers}/>
                <InAppPurchase inAppCounter={totalInAppPurchases}/>
              </Col>
            </Row>
            <Row>
              <FcmNotifiForm os_name="Android"/>
            </Row>
          </Container>
        </div>
        
      </React.Fragment>
    )
  }
}

EnterpriseAndroid.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(EnterpriseAndroid)
