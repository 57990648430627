import React, { Component } from "react"
import {
  Card,
  CardBody,
  Col,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Form,
  Input,
  Alert
} from "reactstrap"
import * as url from "../../../helpers/url_helper"
import {WEB_API_URL} from "../../../helpers/api_helper";
import $ from "jquery"
import Swal from "sweetalert2"

export default class PromoCoinsNotify extends Component {
  constructor(props) {
    super(props)
    this.state = {
		coins: 10,
      	dropdownOpen: false,
    }
    this.toggledropdown = this.toggledropdown.bind(this)
  }

  	handleValidSubmit = (e) => {
		e.preventDefault();
		$(".loading").text(` Loading ...`).css('color', 'red')
		const data = this.state;
		const { coins } = data;
		Swal.fire({
			title: 'Are you sure?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, send it!',
			cancelButtonText: 'No, keep it'
		  }).then((result) => {
			if (result.value) {
				const BToken = localStorage.getItem("gameAuth");
				const BearerToken = JSON.parse(BToken);
				// console.log(BearerToken)
				fetch(`${WEB_API_URL}${url.POST_DASHBOARD_COIN_PROMOTION_NOTIFICATION}`, {
					method: 'post',
					headers: {
						'Authorization': `${BearerToken}`,
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						game_name:"Werewolf",
						os_name: "Web",
						coins
					})
				})
				.then(res => res.json())
				.then(data => {
					const {
						status,
						message
					} = data ; 
					$(".cp_success_message").text(` ${message}`).css('color', 'green').fadeIn('slow').delay(5000).hide(1);;
					$(".loading").text(``)
					this.setState({
						type: "Test",
					})
				})				
			  
			// For more information about handling dismissals please visit
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				$(".loading").text(``)
			}
		  })
  	}

  	handleInputChange = (e) => {
		// e.preventDefault();
		const {name, value} = e.target;
		this.setState({
			[name] : value
		})
	}

	handleInputClick = (e) => {
		const {name, value} = e.target;
		this.setState({
			[name] : value
		})
  	}
	
	onKeyPressEvent = (event) => {
		const keyCode = event.keyCode || event.which;
		const keyValue = String.fromCharCode(keyCode);
		if (!(new RegExp("[0-9]").test(keyValue)))
			event.preventDefault();
		return;
	}
	  

  toggledropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }
  render() {
	  const {coins} = this.state;
    return (
      <React.Fragment>        
	
		<Col lg={4}>
			<Card>
				<CardBody>
				<CardTitle className="h4 mb-4">
					Coin Promotion
					<span className="cp_success_message"></span>
				</CardTitle>

				<Form onSubmit={this.handleValidSubmit}>
					<FormGroup className="row mb-4">
					<Label
						htmlFor="horizontal-coins-Input"
						className="col-sm-4 col-form-label text-md-right"
						style={{textAlign: "center"}}
					>
						Coins
					</Label>
					<Col sm={4}>
						<Input
						value={coins}
						type="text"
						name="coins"
						className="form-control"
						id="horizontal-coins-Input"
						onChange={this.handleInputChange}
						onKeyPress={this.onKeyPressEvent}
						required
						/>
					</Col>
					</FormGroup>
					<FormGroup className="row justify-content-end">
					<Col sm={8}>
						<div>
						<Button
							type="submit"
							color="primary"
							className="w-md"
						>
							Submit
						</Button> &nbsp; &nbsp; &nbsp; <span className="loading"></span>
						</div>
					</Col>
					</FormGroup>
				</Form>
				</CardBody>
			</Card>
		</Col>
      
	  </React.Fragment>
    )
  }
}

