import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
} from "reactstrap"

// Pages Components
import PromoForm from "./Form"
import Redeemed from './Redeemed';
import Remaining from './Remaining'

import * as url from "../../../helpers/url_helper"
import {API_URL} from "../../../helpers/api_helper";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

class MafiaPromo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      totalPromo: 0,
      redeemed_iphone: 0,
      redeemed_ipad: 0,
      redeemed_mac: 0,
      redeemed_pc: 0,
      remaining_iphone: 0,
      remaining_ipad: 0,
      remaining_computer: 0
      
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }
  
  componentDidMount() {
    setTimeout(() => this.setState({ subscribemodal: false }), 2000);
    const BToken = localStorage.getItem("gameAuth");
    const BearerToken = JSON.parse(BToken);
    // console.log(BearerToken)
    fetch(`${API_URL}${url.GET_DASHBOARD_GAME_PROMO}?game=mafia`, {
      method: 'get',
      headers: {
        'Authorization': `${BearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(data => {
      const {
        status,
        redeemed_data: {iphone, ipad, mac, pc},
        remaining_data: {iphone: total_iphone_user, ipad: total_ipad_user, computer: total_computer} 
      } = data ; 
      const remaining_iphone = total_iphone_user - (isNaN(iphone)? 0 : iphone);
      const remaining_ipad = total_ipad_user - (isNaN(ipad)? 0 : ipad);
      const remaining_computer = total_computer - (isNaN(mac)? 0 : mac + isNaN(pc)? 0 : pc);
  
      this.setState({ 
        redeemed_iphone: isNaN(iphone)? 0 : iphone,
        redeemed_ipad: isNaN(ipad)? 0 : ipad,
        redeemed_mac: isNaN(mac)? 0 : mac,
        redeemed_pc: isNaN(pc)? 0 : pc,
        remaining_iphone: isNaN(remaining_iphone)? 0: remaining_iphone,
        remaining_ipad: isNaN(remaining_ipad)? 0: remaining_ipad,
        remaining_computer: isNaN(remaining_computer)? 0: remaining_computer
      })
  
    }).catch(function (error) {
      console.log(error);
    });
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  render() {
    const {redeemed_iphone, redeemed_ipad, redeemed_mac, redeemed_pc, remaining_iphone, remaining_ipad, remaining_computer } = this.state;
    
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Game | AP - Mafia Promo</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Admin Panel")}
              breadcrumbItem={this.props.t(`Mafia Promo`) }
            />
            <Row>
                <Redeemed redeemed_iphone= {redeemed_iphone} redeemed_ipad={redeemed_ipad} redeemed_mac={redeemed_mac} redeemed_pc ={redeemed_pc} />
            </Row>
            <Row>
              <Remaining remaining_iphone = {remaining_iphone} remaining_ipad = {remaining_ipad} remaining_computer = {remaining_computer}/>
            </Row>
            <Row>
              <PromoForm />
            </Row>
          </Container>
        </div>
        
      </React.Fragment>
    )
  }
}

MafiaPromo.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(MafiaPromo)
