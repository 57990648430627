import PropTypes from "prop-types"
import React, { Component } from "react"

//Simple bar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
  }

  componentDidMount() {
    this.initMenu()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  render() {
    return (
      <React.Fragment>
        <SimpleBar style={{ maxHeight: "100%" }} ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="/dashboard" className="waves-effect">
                  <i className="bx bxs-dashboard" />
                  <span>{this.props.t("Overview")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="waves-effect">
                  <i className="bx bx-card" />
                  <span>{this.props.t("iOS Games")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/mafia" className="waves-effect">
                      <i className="bx bxs-report" />
                      <span>{this.props.t("Mafia")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/werewolf" className="waves-effect">
                      <i className="bx bx-align-left" />
                      <span>{this.props.t("Werewolf")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/resistance" className="waves-effect">
                      <i className="bx bxs-dock-bottom" />
                      <span>{this.props.t("Resistance")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/avalon" className="waves-effect">
                      <i className="bx bxs-bar-chart-square" />
                      <span>{this.props.t("Avalon")}</span>
                    </Link>
                  </li>
                </ul>
              </li>


              <li>
                <Link to="/ios-report" className="waves-effect">
                  <i className="bx bxs-grid" />
                  <span>{this.props.t("iOS Report")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="waves-effect">
                  <i className="bx bx-dialpad-alt" />
                  <span>{this.props.t("iOS App Version")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/mafia-app-version/IOS">{this.props.t("Mafia")}</Link>
                  </li>
                  <li>
                    <Link to="/werewolf-app-version/IOS">{this.props.t("Werewolf")}</Link>
                  </li>
                  <li>
                    <Link to="/resistance-app-version/IOS">{this.props.t("Resistance")}</Link>
                  </li>
                  <li>
                    <Link to="/avalon-app-version/IOS">{this.props.t("Avalon")}</Link>
                  </li>
                  <li>
                    <Link to="/enterprise-app-version/IOS">{this.props.t("Enterprise")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="waves-effect">
                  <i className="bx bx-grid" />
                  <span>{this.props.t("Android Games")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/mafia-android" className="waves-effect">
                      <i className="bx bxs-report" />
                      <span>{this.props.t("Mafia")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/werewolf-android" className="waves-effect">
                      <i className="bx bx-align-left" />
                      <span>{this.props.t("Werewolf")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/resistance-android" className="waves-effect">
                      <i className="bx bxs-dock-bottom" />
                      <span>{this.props.t("Resistance")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/avalon-android" className="waves-effect">
                      <i className="bx bxs-bar-chart-square" />
                      <span>{this.props.t("Avalon")}</span>
                    </Link>
                  </li>
                </ul>
              </li>


              <li>
                <Link to="/android-report" className="waves-effect">
                  <i className="bx bxs-report" />
                  <span>{this.props.t("Android Report")}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="waves-effect">
                  <i className="bx bx-shape-circle" />
                  <span>{this.props.t("Android App Version")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/mafia-app-version/ANDROID">{this.props.t("Mafia")}</Link>
                  </li>
                  <li>
                    <Link to="/werewolf-app-version/ANDROID">{this.props.t("Werewolf")}</Link>
                  </li>
                  <li>
                    <Link to="/resistance-app-version/ANDROID">{this.props.t("Resistance")}</Link>
                  </li>
                  <li>
                    <Link to="/avalon-app-version/ANDROID">{this.props.t("Avalon")}</Link>
                  </li>
                  <li>
                    <Link to="/enterprise-app-version/ANDROID">{this.props.t("Enterprise")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="waves-effect">
                  <i className="bx bx-aperture" />
                  <span>{this.props.t("Mobile Config")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/mafia-config">{this.props.t("Mafia")}</Link>
                  </li>
                  <li>
                    <Link to="/werewolf-config">{this.props.t("Werewolf")}</Link>
                  </li>
                  <li>
                    <Link to="/resistance-config">{this.props.t("Resistance")}</Link>
                  </li>
                  <li>
                    <Link to="/avalon-config">{this.props.t("Avalon")}</Link>
                  </li>
                </ul>
              </li>
              
              <li>
                <Link to="/#" className="waves-effect">
                  <i className="bx bx-layout" />
                  <span>{this.props.t("Web Games")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/web-dashboard" className="waves-effect">
                      <i className="bx bxs-dashboard" />
                      <span>{this.props.t("Overview")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/web-mafia" className="waves-effect">
                      <i className="bx bxs-report" />
                      <span>{this.props.t("Mafia")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/web-werewolf" className="waves-effect">
                      <i className="bx bx-align-left" />
                      <span>{this.props.t("Werewolf")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/web-resistance" className="waves-effect">
                      <i className="bx bxs-dock-bottom" />
                      <span>{this.props.t("Resistance")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/web-avalon" className="waves-effect">
                      <i className="bx bxs-bar-chart-square" />
                      <span>{this.props.t("Avalon")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/web-date-range" className="waves-effect">
                      <i className="bx bxs-report" />
                      <span>{this.props.t("Report")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/#" className="waves-effect">
                      <i className="bx bx-aperture" />
                      <span>{this.props.t("Game Config")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/web-mafia-config">
                          <i className="bx bx-list-ol" />
                          <span>{this.props.t("Mafia")}</span>
                          </Link>
                      </li>
                      <li>
                        <Link to="/web-werewolf-config">
                          <i className="bx bx-list-ol" />
                          <span>{this.props.t("Werewolf")}</span>
                          </Link>
                      </li>
                      <li>
                        <Link to="/web-resistance-config">
                          <i className="bx bx-list-ol" />
                          <span>{this.props.t("Resistance")}</span>
                          </Link>
                      </li>
                      <li>
                        <Link to="/web-avalon-config">
                          <i className="bx bx-list-ol" />
                          <span>{this.props.t("Avalon")}</span>
                          </Link>
                      </li>
                    </ul>
                  </li>      
                </ul>
              </li>
              
              <li>
                <Link to="/looksee-users-info" className="waves-effect">
                  <i className="bx bx-customize" />
                  <span>{this.props.t("Looksee")}</span>
                </Link>
              </li>
              <li>
                <Link to="/looksee-report" className="waves-effect">
                  <i className="bx bxs-report" />
                  <span>{this.props.t("Looksee Report")}</span>
                </Link>
              </li>
              <li>
                <Link to="/looksee-config-list" className="waves-effect">
                  <i className="bx bx-list-ol" />
                  <span>{this.props.t("Looksee Config")}</span>
                </Link>
              </li>

              <li>
                <Link to="/solo-users-info" className="waves-effect">
                  <i className="bx bxs-low-vision" />
                  <span>{this.props.t("Solo")}</span>
                </Link>
              </li>
              <li>
                <Link to="/solo-report" className="waves-effect">
                  <i className="bx bxs-piano" />
                  <span>{this.props.t("Solo Report")}</span>
                </Link>
              </li>
              <li>
                <Link to="/solo-config-list" className="waves-effect">
                  <i className="bx bxs-box" />
                  <span>{this.props.t("Solo Config")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="waves-effect">
                  <i className="bx bx-align-justify" />
                  <span>{this.props.t("Giveaway Promo")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">                  
                  <li>
                    <Link to="/looksee-promo" className="waves-effect">
                      <i className="bx bx-file" />
                      <span>{this.props.t("Looksee")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/mafia-promo" className="waves-effect">
                      <i className="bx bx-wallet-alt" />
                      <span>{this.props.t("Mafia")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/werewolf-promo" className="waves-effect">
                      <i className="bx bx-store-alt" />
                      <span>{this.props.t("Werewolf")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/resistance-promo" className="waves-effect">
                      <i className="bx bx-memory-card" />
                      <span>{this.props.t("Resistance")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/avalon-promo" className="waves-effect">
                      <i className="bx bx-shape-square" />
                      <span>{this.props.t("Avalon")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="waves-effect">
                  <i className="bx bx-buildings" />
                  <span>{this.props.t("Enterprise Games")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">                

                  <li>
                    <Link to="/enterprise-ios" className="waves-effect">
                      <i className="bx bx-list-ol" />
                      <span>{this.props.t("iOS")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/enterprise-android" className="waves-effect">
                      <i className="bx bx-list-ol" />
                      <span>{this.props.t("Android")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/enterprise-web" className="waves-effect">
                      <i className="bx bx-list-ol" />
                      <span>{this.props.t("Web")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="waves-effect">
                  <i className="bx bxs-report" />
                  <span>{this.props.t("Enterprise Report")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">                 

                  <li>
                    <Link to="/enterprise/ios-report" className="waves-effect">
                      <i className="bx bx-list-ol" />
                      <span>{this.props.t("iOS")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/enterprise/android-report" className="waves-effect">
                      <i className="bx bx-list-ol" />
                      <span>{this.props.t("Android")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/enterprise/web-report" className="waves-effect">
                      <i className="bx bx-list-ol" />
                      <span>{this.props.t("Web")}</span>
                    </Link>
                  </li>
                </ul>
              </li> 
              <li>
                <Link to="/#" className="waves-effect">
                  <i className="bx bx-aperture" />
                  <span>{this.props.t("Enterprise Config")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">  
                  <li>
                    <Link to="/enterprise/mafia-config">
                    <i className="bx bx-list-ol" />
                      {this.props.t("Mafia")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/enterprise/werewolf-config">
                      <i className="bx bx-list-ol" />
                      {this.props.t("Werewolf")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/enterprise/resistance-config">
                      <i className="bx bx-list-ol" />
                      {this.props.t("Resistance")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/enterprise/avalon-config">
                      <i className="bx bx-list-ol" />
                      {this.props.t("Avalon")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/enterprise/web-config" className="waves-effect">
                      <i className="bx bx-list-ol" />
                      {this.props.t("Web")}
                    </Link>
                  </li>
                </ul>
              </li>

            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
}

export default withRouter(withTranslation()(SidebarContent))
