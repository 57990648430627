import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
} from "reactstrap"

// Pages Components
import FcmNotifiForm from "./FcmNotifiForm"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

class EnterpriseFcm extends Component {
  constructor(props) {
    super(props)
    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }
  componentWillMount() {}

  componentDidMount() {
    setTimeout(() => this.setState({ subscribemodal: false }), 2000);
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Game | AP - Enterprise</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Admin Panel")}
              breadcrumbItem={this.props.t("Enterprise FCM")}
            />  
            <Row>
              <FcmNotifiForm />
            </Row>
          </Container>
        </div>
        
      </React.Fragment>
    )
  }
}

EnterpriseFcm.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(EnterpriseFcm)
