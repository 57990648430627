import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"

import avatar from "../../assets/images/users/avatar-1.jpg"

export default class TotalNewUsers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
    this.toggledropdown = this.toggledropdown.bind(this)
  }

  toggledropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }
  render() {
    const {user} = this.props;
    return (
      <React.Fragment>        
        <Col xl={12}>          
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <h5 className="card-title mb-3 me-2">Users</h5>
              </div>

              <div className="d-flex flex-wrap">
                <div>
                  <h4 className="mb-3">{user}</h4>                  
                </div>
                <div className="ms-auto align-self-end">
                  <i className="bx bx-user-plus display-6 text-light"></i>
                </div>
              </div>
            </CardBody>
          </Card>          
        </Col>
      </React.Fragment>
    )
  }
}
