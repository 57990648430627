import React, { Component } from "react"
import {
  Card,
  CardBody,
  Col,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Form,
  Input,
  Alert
} from "reactstrap"
import * as url from "../../../helpers/url_helper"
import {API_URL} from "../../../helpers/api_helper";
import $ from "jquery"
import Swal from "sweetalert2"

export default class PromoForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			totalPromo: 0,
			username: "",
			password: "",
			dropdownOpen: false,
		}
		this.toggledropdown = this.toggledropdown.bind(this)
	}

	componentDidMount() {
		setTimeout(() => this.setState({ subscribemodal: false }), 2000);
		const BToken = localStorage.getItem("gameAuth");
		const BearerToken = JSON.parse(BToken);
		// console.log(BearerToken)
		fetch(`${API_URL}${url.GET_GAME_PROMO_COUNT}?game=werewolf`, {
		method: 'get',
		headers: {
			'Authorization': `${BearerToken}`,
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
		})
		.then(res => res.json())
		.then(data => {
		const {
			status,
			total: totalPromo
		} = data ; 
	
		this.setState({ 
			totalPromo
		})
	
		}).catch(function (error) {
			console.log(error);
		});
	}

  	handleValidSubmit = (e) => {
		e.preventDefault();
		$(".loading").text(` Loading ...`).css('color', 'red')
		const data = this.state;
		const { username, password} = data;

		const BToken = localStorage.getItem("gameAuth");
		const BearerToken = JSON.parse(BToken);
		// console.log(BearerToken)
		fetch(`${API_URL}${url.POST_GAME_PROMO}`, {
			method: 'post',
			headers: {
				'Authorization': `${BearerToken}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				username,
				password,
				game: `werewolf`
			})
		})
		.then(res => res.json())
		.then(data => {
			const {
				status,
				message
			} = data ; 
			$(".success_message").text(` ${message}`).css('color', 'green').fadeIn('slow').delay(3000).hide(1);
			$(".loading").text(``)
			this.setState({
				password: "",
				username: "",
			})
			fetch(`${API_URL}${url.GET_GAME_PROMO_COUNT}?game=werewolf`, {
				method: 'get',
				headers: {
					'Authorization': `${BearerToken}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
				})
				.then(res => res.json())
				.then(data => {
				const {
					status,
					total: totalPromo
				} = data ; 
			
				this.setState({ 
					totalPromo
				})
			
				}).catch(function (error) {
				console.log(error);
				});
		}).catch(err => {
			$(".success_message").text(` ${err}`).css('color', 'red').fadeIn('slow').delay(3000).hide(1);
		});
		
  	}

  	handleInputChange = (e) => {
		// e.preventDefault();
		this.setState({
			[e.target.name] : e.target.value
		})
	}

	handleInputClick = (e) => {
		this.setState({
			[e.target.name] : e.target.value
		})
  	}
	  

  toggledropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }
  render() {
	const {username, password, totalPromo } = this.state;

    return (
      <React.Fragment>        
		  <h5>Add Promo Account</h5>  
		<Col lg={12}>
			<Card>
				<div className="avatar-sm product-ribbon">
					{/* <span
                      className= "avatar-title rounded-circle  bg-primary" 
                    >{totalPromo}</span> */}
					<Button
						type="button"
						// color="primary"
						className="avatar-title"
					>
						{totalPromo}
					</Button>
				</div>
				<CardBody>
				<CardTitle className="h4 mb-4">
					<span className="success_message"></span>
				</CardTitle>

				<Form onSubmit={this.handleValidSubmit}>
					<FormGroup className="row mb-4">
					<Label
						sm={3} md={2}
						htmlFor="horizontal-username-Input"
						className="col-form-label text-md-right"
						style={{textAlign: "center"}}
					>
						username
					</Label>
					<Col sm={9} md={6}>
						<Input
						value={username}
						type="text"
						name="username"
						// placeholder="Enter Username..."
						className="form-control"
						id="horizontal-username-Input"
						onChange={this.handleInputChange}
						required
						/>
					</Col>
					</FormGroup>
					<FormGroup className="row mb-4">
						<Label
							sm={3} md={2}
							htmlFor="horizontal-password-Input"
							className="col-form-label text-md-right"
							style={{textAlign: "center"}}
						>
							password
						</Label>
						<Col sm={9} md={6}>
							<Input
							value={password}
							type="text"
							name="password"
							// placeholder="Enter Password..."
							className="form-control"
							id="horizontal-password-Input"
							onChange={this.handleInputChange}
							required
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row justify-content-end">
					<Col sm={9} md={10}>
						<div>
						<Button
							type="submit"
							color="primary"
							className="w-md"
						>
							Submit
						</Button> &nbsp; &nbsp; &nbsp; <span className="loading"></span>
						</div>
					</Col>
					</FormGroup>
				</Form>
				</CardBody>
			</Card>
		</Col>
      
	  </React.Fragment>
    )
  }
}

