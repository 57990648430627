import React, { Component } from "react"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import { Link } from "react-router-dom"

class ConfigList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      field: "",
      value: "",
      _id: ""
    }
  }
  updateItem = (e) => {
    // e.preventDefault();
    console.log(e);
    this.setState({
      field: e.field,
      value: e.value,
      _id: e._id
    })
  }


  render() {
    const { game_config } = this.props;
    return (
      <React.Fragment> 
      {
        game_config.map((data, index) => (
          <Col md={12}>
            <CardTitle className="h4">{(data._id === 'Others'? 'Other': data._id )}</CardTitle>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                  <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th style={{width: "10%"}}>#</th>
                            <th style={{width: "35%"}}>Field Name</th>
                            <th style={{width: "25%"}}>Value</th>
                            <th style={{width: "25%"}}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            data.types_record.map((type_data, index) => (
                              <tr key={type_data._id}>
                                <th scope="row" style= {{width: "10%"}}>{index + 1}</th>
                                <td style={{width: "35%"}}>{type_data.field}</td>
                                <td style={{width: "25%"}}>{type_data.value}</td>
                                <td style={{width: "25%"}}>
                                    <Link to={{
                                        pathname: "/web-config-edit",
                                        data: type_data, // your data array of objects
                                        game: 'Werewolf'
                                    }} >
                                    <i className="fas fa-edit"></i> Edit
                                  </Link>
                                  
                                  </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </div>

                </CardBody>
              </Card>
            </Col>
          ))
        }

      </React.Fragment>
    )
  }
}

export default ConfigList
