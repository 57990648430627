import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import $ from 'jquery'
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import * as url from "../../helpers/url_helper"
import {API_URL} from "../../helpers/api_helper";

import TotalUsers from "./TotalUsers"
import TotalGamePlayers from "./TotalGamePlayers"
import TotalSubscribes from "./TotalSubscribes"
import InAppPurchase from "./InAppPurchase"
import VideoAds from "./VideoAds"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      totalUser: 0,
      iosGameUser: 0,
      androidGameUser: 0,

      iOSSubscribers: 0,
			androidSubscribers: 0,

      TotalAds: 0, 
      iosRewardedAds: 0, 
      androidRewardedAds: 0, 
      iosPostGameAds: 0,
      androidPostGameAds: 0,

      inAppPurchaseCounter: 0,
      totalGamePlayer: 0,
      totalGames: 0,
      loading: false
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }

  componentWillMount() {
    // this.setState({loading: true});
    const BToken = localStorage.getItem("gameAuth");
    const BearerToken = JSON.parse(BToken);
    // console.log(BearerToken)
    $("#loadingIcon").text("Loading ...").css('color', 'red');
    fetch(`${API_URL}${url.GET_DASHBOARD_COUNTER_INFO}`, {
      method: 'get',
      headers: {
        'Authorization': `${BearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(data => {
      const {
        status,
        TotalAds,  
        // RewardedAds,  
        // PostGameAds,
        iosRewardedAds, 
				androidRewardedAds,
				iosPostGameAds,
				androidPostGameAds,


        iOSSubscribers,
				androidSubscribers,
        totalUser,

				iosGameUser,
        androidGameUser,

        inAppPurchaseCounter
      } = data ; 
  
      this.setState({ 
        TotalAds,  
        iosRewardedAds, 
				androidRewardedAds,
				iosPostGameAds,
				androidPostGameAds,

        iOSSubscribers,
				androidSubscribers,
        totalUser,
        iosGameUser,
        androidGameUser,
        
        inAppPurchaseCounter,
        loading: true
      })
  
    }).catch(function (error) {
      console.log(error);
    });
    Promise.all([
      fetch(`${API_URL}${url.GET_DASHBOARD_GAME_PLAYER_MAFIA}`, {
          method: 'get',
          headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(value => value.json()),
      fetch(`${API_URL}${url.GET_DASHBOARD_GAME_PLAYER_RESISTANCE}`, {
          method: 'get',
          headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(value => value.json()),
      fetch(`${API_URL}${url.GET_DASHBOARD_GAME_PLAYER_AVALON}`, {
          method: 'get',
          headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
      }).then(value => value.json()),
      fetch(`${API_URL}${url.GET_DASHBOARD_GAME_PLAYER_WEREWOLF}`, {
        method: 'get',
        headers: {
          'Authorization': `${BearerToken}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
        }).then(value => value.json())
    ]).then(([mafiaInfo, registanceInfo, avalonInfo, werewolfInfo]) => {
        const {
          gamePlayersCount: mGamePlayersCount,
          totalGames: mTotalGames
        } = mafiaInfo;
        const {
          gamePlayersCount: rGamePlayersCount,
          totalGames: rTotalGames
        } = registanceInfo;
        const {
          gamePlayersCount: aGamePlayersCount,
          totalGames: aTotalGames
        } = avalonInfo;
        const {
          gamePlayersCount: wGamePlayersCount,
          totalGames: wTotalGames
        } = werewolfInfo;
        let totalGamePlayer = mGamePlayersCount + rGamePlayersCount + aGamePlayersCount + wGamePlayersCount;
        let totalGames = mTotalGames + rTotalGames + aTotalGames + wTotalGames;
        $("#loadingIcon").text("");
        this.setState({ 
          totalGamePlayer,
          totalGames,
          loading: true
        })
    }).catch((err) => {
        console.log(err);
    });
  }
  
  componentDidMount() {
    setTimeout(() => this.setState({ subscribemodal: false }), 2000);
    

  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  render() {
    const { 
      TotalAds,
      iosRewardedAds, 
      androidRewardedAds,
      iosPostGameAds,
      androidPostGameAds,
      iOSSubscribers,
			androidSubscribers,
      
      totalUser,
      iosGameUser,
			androidGameUser,

      inAppPurchaseCounter,
      totalGames,
      totalGamePlayer,
      loading 
    } = this.state;
    
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Game | AP - Dashboard</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Dashboards")}
              breadcrumbItem={this.props.t("Dashboard")}
              
            />
            
            <Row>
            <Col xl={4}> 
              <TotalUsers user={totalUser} title="Users"/>
              <TotalUsers user={iosGameUser} title="iOS Users"/>
              <TotalUsers user={androidGameUser} title="Android Users"/>

              <TotalSubscribes subscribes={iOSSubscribers} title="iOS Subscribers"/>
              <TotalSubscribes subscribes={androidSubscribers} title="Android Subscribers"/>

            </Col>
            <Col xl={4}> 
              <TotalGamePlayers totalGames= {totalGames} totalPlayers = {totalGamePlayer}/>
              <InAppPurchase inAppCounter={inAppPurchaseCounter}/>
            </Col>
            <Col xl={4}> 
              <VideoAds total={TotalAds} title="Total Ads"/>
              <VideoAds total={iosPostGameAds} title="iOS Post-Game Ads"/>
              <VideoAds total={iosRewardedAds} title="iOS Rewarded Ads"/>
              <VideoAds total={androidPostGameAds} title="Android Post-Game Ads"/>
              <VideoAds total={androidRewardedAds} title="Android Rewarded Ads"/>
            </Col>
            </Row>
          </Container>
        </div>
        
      </React.Fragment>
    )

  }
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)
