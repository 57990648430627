import axios from "axios"

const token = JSON.parse(localStorage.getItem("gameAuth"));

//apply base url for axios
// export const API_URL = "http://localhost:3100/api/v1"
export const API_URL = `https://admin-panel.irlgames.app/api/v1`;
export const SOLO_API_URL =  API_URL + `/solo`
export const LOOKSEE_API_URL =  API_URL + `/looksee`
export const WEB_API_URL =  API_URL + `/web`
export const ENTERPRISE_API_URL =  API_URL + `/enterprise`

const axiosApi = axios.create({
  baseURL: API_URL,
});


axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

