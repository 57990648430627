import React, { Component } from "react"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import { Link } from "react-router-dom"
import { sortedIndex } from "lodash"

class LookseePolicyList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      field: "",
      value: "",
      _id: ""
    }
  }
  updateItem = (e) => {
    // e.preventDefault();
    console.log(e);
    this.setState({
      field: e.field,
      value: e.coin,
      _id: e._id
    })
  }


  render() {
    const { policy } = this.props;
    return (
      <React.Fragment> 
        <Col md={12}>
            <h5 className=" mb-0 font-size-16">FREE USAGE LIMITS</h5> <br/>
            <Card>
              <CardBody>
                <div className="table-responsive">
                <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th style={{width: "10%"}}>#</th>
                          <th style={{width: "35%"}}>Type</th>
                          <th style={{width: "25%"}}>Value</th>
                          <th style={{width: "25%"}}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          policy.map((type_data, index) => (
                            <tr key={index}>
                              <th scope="row" style= {{width: "10%"}}>{index + 1}</th>
                              <td style={{width: "35%"}}>{type_data.type}</td>
                              <td style={{width: "45%"}}>{type_data.value}</td>
                              <td style={{width: "25%"}}>
                                  <Link to={{
                                      pathname: "/solo-policy-edit",
                                      data: type_data, // your data array of objects                                      
                                  }} >
                                  <i className="fas fa-edit"></i> Edit
                                </Link>
                                
                                </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </div>

              </CardBody>
            </Card>
          </Col>

      </React.Fragment>
    )
  }
}

export default LookseePolicyList
