import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import $ from 'jquery'
import {
  Container,
  Row,
  Col
} from "reactstrap"
import * as url from "../../../helpers/url_helper"
import {WEB_API_URL, WEB_MAFIA_API_URL, WEB_WEREWOLF_API_URL} from "../../../helpers/api_helper";

import TotalUsers from "./TotalUsers"
import TotalGamePlayers from "./TotalGamePlayers"
import TotalSubscribes from "./TotalSubscribes"
import InAppPurchase from "./InAppPurchase"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

class WebDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      totalUser: 0,

      totalSubscribers: 0,

      TotalAds: 0, 
      iosRewardedAds: 0, 
      androidRewardedAds: 0, 
      iosPostGameAds: 0,
      androidPostGameAds: 0,

      inAppPurchaseCounter: 0,
      totalCoinSpent: 0,
      totalGamePlayer: 0,
      totalGames: 0,
      loading: false
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }

  componentWillMount() {
    // this.setState({loading: true});
    const BToken = localStorage.getItem("gameAuth");
    const BearerToken = JSON.parse(BToken);
    // console.log(BearerToken)
    $("#loadingIcon").text("Loading ...").css('color', 'red');
    fetch(`${WEB_API_URL}${url.GET_DASHBOARD_COUNTER_INFO}`, {
      method: 'get',
      headers: {
        'Authorization': `${BearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(data => {
      const {
        status,
        totalSubscribers,
        totalUser,
        inAppPurchaseCounter,
        totalCoinSpent
      } = data ; 
  
      this.setState({

        totalSubscribers,
        totalUser,
        
        inAppPurchaseCounter,
        loading: true
      })
  
    }).catch(function (error) {
      console.log(error);
    });
    Promise.all([
      fetch(`${WEB_API_URL}${url.GET_DASHBOARD_GAME_PLAYER_RESISTANCE}`, {
          method: 'get',
          headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(value => value.json()),
      fetch(`${WEB_API_URL}${url.GET_DASHBOARD_GAME_PLAYER_AVALON}`, {
          method: 'get',
          headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
      }).then(value => value.json()),
      // fetch(`${WEB_MAFIA_API_URL}${url.GET_DASHBOARD_GAME_PLAYER_MAFIA}`, {
      //     method: 'get',
      //     headers: {
      //       'Authorization': `${BearerToken}`,
      //       'Accept': 'application/json',
      //       'Content-Type': 'application/json'
      //     }
      //   }).then(value => value.json()),
      // fetch(`${WEB_WEREWOLF_API_URL}${url.GET_DASHBOARD_GAME_PLAYER_WEREWOLF}`, {
      //   method: 'get',
      //   headers: {
      //     'Authorization': `${BearerToken}`,
      //     'Accept': 'application/json',
      //     'Content-Type': 'application/json'
      //   }
      //   }).then(value => value.json())
    ]).then(([registanceInfo, avalonInfo]) => {
      const {
        gamePlayersCount: rGamePlayersCount,
        totalGames: rTotalGames
      } = registanceInfo;
      const {
        gamePlayersCount: aGamePlayersCount,
        totalGames: aTotalGames
      } = avalonInfo;
      // , mafiaInfo,  werewolfInfo
      // const {
      //   gamePlayersCount: mGamePlayersCount,
      //   totalGames: mTotalGames
      // } = mafiaInfo;
      // const {
      //   gamePlayersCount: wGamePlayersCount,
      //   totalGames: wTotalGames
      // } = werewolfInfo;
      let totalGamePlayer = rGamePlayersCount + aGamePlayersCount ;
      let totalGames = rTotalGames + aTotalGames; // mTotalGames   + wTotalGames;
      $("#loadingIcon").text("");
      this.setState({ 
        totalGamePlayer,
        totalGames,
        loading: true
      })
    }).catch((err) => {
        console.log(err);
    });
  }
  
  componentDidMount() {
    setTimeout(() => this.setState({ subscribemodal: false }), 2000);    

  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  render() {
    const { 
      totalSubscribers,
      
      totalUser,
      totalCoinSpent,
      inAppPurchaseCounter,
      totalGames,
      totalGamePlayer,
      loading 
    } = this.state;
    
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Game | AP - Web Game Dashboard</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Dashboards")}
              breadcrumbItem={this.props.t("Web Game Dashboard")}
              
            />
            
            <Row>
            <Col xl={4}> 
              <TotalUsers user={totalUser} title="Users"/>

              <TotalSubscribes subscribes={totalSubscribers} title="Subscribers"/>

            </Col>
            <Col xl={4}> 
              <TotalGamePlayers totalGames= {totalGames} totalPlayers = {totalGamePlayer}/>
            </Col>
            <Col xl={4}> 
              <InAppPurchase dataCount={inAppPurchaseCounter} title = "In-App Purchases"/>
              <InAppPurchase dataCount={totalCoinSpent} title = "Coins Spent" />
            </Col>
            </Row>
          </Container>
        </div>
        
      </React.Fragment>
    )

  }
}

WebDashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(WebDashboard)
