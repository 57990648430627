import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import * as url from "../../../helpers/url_helper"
import {WEB_API_URL} from "../../../helpers/api_helper";

// Pages Components
import TotalGamePlayers from "./TotalGamePlayers"
import CoinsSpent from "../Avalon/CoinsSpent"
import PromoCoinsNotify from "./PromoCoinsNotify"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

class Werewolf extends Component {
  constructor(props) {
    super(props)
    this.state = {
      totalUser: 0,
      totalCoinSpent: 0,
      totalGamePlayer: 0,
      totalGames: 0
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }
  componentWillMount() {
    const BToken = localStorage.getItem("gameAuth");
    const BearerToken = JSON.parse(BToken);
    // console.log(BearerToken)
    fetch(`${WEB_API_URL}${url.GET_DASHBOARD_GAME_WISH_INFO}?game_name=Werewolf`, {
      method: 'get',
      headers: {
        'Authorization': `${BearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(data => {
      const {
        status,
        totalUser,
        totalCoinSpent
      } = data ; 
  
      this.setState({ 
        totalUser,
        totalCoinSpent
      })
  
    }).catch(function (error) {
      console.log(error);
    });
    Promise.all([
      fetch(`${WEB_API_URL}${url.GET_DASHBOARD_GAME_PLAYER_WEREWOLF}`, {
          method: 'get',
          headers: {
            'Authorization': `${BearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(value => value.json()),      
    ]).then(([werewolfInfo]) => {
        const {
          totalGames,
          gamePlayersCount
        } = werewolfInfo;
        
        this.setState({ 
          totalGames,
          totalGamePlayer: gamePlayersCount
        })
    }).catch((err) => {
        console.log(err);
    });

  }

  componentDidMount() {
    setTimeout(() => this.setState({ subscribemodal: false }), 2000);
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  render() {
    const {
      totalCoinSpent,
      totalGames, 
      totalGamePlayer 
    } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Game | AP - Werewolf-iOS</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Admin Panel")}
              breadcrumbItem={this.props.t("Werewolf")}
            />

            <Row>
              <Col xl={4}> 
                <TotalGamePlayers totalGames={totalGames} totalPlayers = {totalGamePlayer}/>
              </Col>
              <Col xl={4}> 
                <CoinsSpent totalCoinSpent={totalCoinSpent}/>
              </Col>
            </Row>
            <Row>
              <PromoCoinsNotify />
            </Row>
          </Container>
        </div>
        
      </React.Fragment>
    )
  }
}

Werewolf.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Werewolf)
