import React, { Component } from "react"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import { Link } from "react-router-dom"

class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      field: "",
      value: "",
      _id: ""
    }
  }
  updateItem = (e) => {
    // e.preventDefault();
    console.log(e);
    this.setState({
      field: e.field,
      value: e.coin,
      _id: e._id
    })
  }


  render() {
    const { game_config, os_name } = this.props;
    console.log(os_name);
    
    let key = 1;
    return (
      <React.Fragment>
        <Col md={12}>
          <Card>
            <CardBody>
              {/* <CardTitle className="h4">Game Config List</CardTitle> */}

              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Field Name</th>
                      <th>Value</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      game_config.map((data, index) => (
                        (data.game_name === 'Mafia') && (data.field.split("_").shift() === os_name) &&
                        <tr key={data._id}>
                          <td scope="row">{key++}</td>
                          <td>{data.field}</td>
                          <td>{data.value}</td>
                          <td>
                              <Link to={{
                                  pathname: "/app-version-edit",
                                  data: Object.assign(data, {os_name}), // your data array of objects
                              }} >
                              <i className="fas fa-edit"></i> Edit
                            </Link>
                            
                            </td>
                        </tr>
                      ))                     
                    }

                    {

                       game_config.map((data, index) => (
                        (data.game_name === 'Mafia') && (data.field.split("_").shift() !== 'ANDROID') && (os_name === 'IOS') &&
                        <tr key={data._id}>
                          <td scope="row">{key++}</td>
                          <td>{data.field}</td>
                          <td>{data.value}</td>
                          <td>
                              <Link to={{
                                  pathname: "/app-version-edit",
                                  data: Object.assign(data, {os_name}), // your data array of objects
                              }} >
                              <i className="fas fa-edit"></i> Edit
                            </Link>
                            
                            </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>

      </React.Fragment>
    )
  }
}

export default List
